import BracketSet from "./BracketSet";
import BracketEntrant from "./BracketEntrant";
import {Entrant} from "../../../types/obf";

interface BracketMetaData {
    date?: string
    gameName?: string
}

class BracketEvent {
    name?: string
    numberOfEntrants = 3
    root
    winnersRoot?: BracketSet
    losersRoot?: BracketSet
    entrants?: Array<BracketEntrant|Entrant|undefined>
    layout: ChallongeTournamentTypes
    other: BracketMetaData = {
        date: new Date().toDateString(),
        gameName: "DNF Duel"
    }

    constructor(props: {
        name?: string
        numberOfEntrants: number
        entrants?: Array<BracketEntrant|Entrant|undefined>
        layout: ChallongeTournamentTypes
        metaData?: BracketMetaData
    }) {
        const {
            name,
            numberOfEntrants,
            entrants,
            layout,
            metaData
        } = props
        this.name = name || "Generic OBF Tournament"
        this.numberOfEntrants = numberOfEntrants
        this.layout = layout
        this.root = this.createBracket()
        this.winnersRoot = this.root
        this.entrants = this.assignEntrants(entrants as unknown as Array<BracketEntrant|Entrant|undefined>)
        this.addMetaData(metaData)
        if (layout === 'Double Elimination') this.root = this.attachLosersBracket(this.root!)
    }

    assignEntrants (entrants: Array<Entrant|BracketEntrant|undefined>) {
        return entrants
    }

    createBracket(size: number = this.numberOfEntrants) {
        if (size < 2) return

        let currentRound = 1
        let previousRoundSets = [] as unknown as BracketSet[]
        let root

        const numberOfRounds = this.calculateRounds(size)
        const byesFromRound1 = this.findHighestPowerOf2(size) - size
        const calculateNumberOfSets = (round: number) => {
            switch (round) {
                case 1:
                    return (size - byesFromRound1) / 2
                case 2:
                    return (byesFromRound1 + previousRoundSets.length) / 2
                default:
                    return previousRoundSets.length / 2
            }
        }

        const winnersSets = []
        while (currentRound <= numberOfRounds) {
            const lastIndex = previousRoundSets.length ? previousRoundSets.slice(-1)[0].setId : 0
            const currentRoundSets = []

            for (let index = 0; index < calculateNumberOfSets(currentRound); index++) {
                currentRoundSets.push(new BracketSet({
                    setId: index + 1 + (lastIndex || 0),
                    round: currentRound
                }))
            }

            switch (currentRound) {
                case 1:
                    break
                case 2:
                    if (this.numberOfEntrants > 4) {
                        const weavedSets = this.weaveItems(previousRoundSets)
                        const topHalf = weavedSets[0]
                        const bottomHalf = weavedSets[1]
                        const round2TopHalf = currentRoundSets.slice(0, currentRoundSets.length / 2)
                        const round2BottomHalf = currentRoundSets.slice(currentRoundSets.length / 2)

                        while (topHalf.length) {
                            round2TopHalf.forEach((set) => { set.addSet(topHalf.shift()) })
                            round2TopHalf.reverse()
                        }

                        while (bottomHalf.length) {
                            round2BottomHalf.forEach((set) => { set.addSet(bottomHalf.shift()) })
                            round2BottomHalf.reverse()
                        }
                    } else {
                        currentRoundSets.forEach((set) => {
                            set.addSet(previousRoundSets.shift())
                            set.addSet(previousRoundSets.shift())
                        })
                    }
                    break
                default:
                    currentRoundSets.forEach((set) => {
                        set.addSet(previousRoundSets.shift())
                        set.addSet(previousRoundSets.shift())
                    })
                    break
            }

            previousRoundSets = currentRoundSets
            winnersSets.push(...currentRoundSets)
            currentRound++
        }

        root = winnersSets && winnersSets.slice(-1)![0]

        return root
    }

    weaveItems (items: any[]) {
        if (items.length === 1) return [items, [undefined]]
        if (items.length === 2) return [[items.shift()], [items.shift()]]
        const topHalf = [items.shift()] as unknown as Array<any|undefined>
        const bottomHalf = [items.shift()] as unknown as Array<any|undefined>

        let i = 4
        while (items.length) {
            if (i !== 4 && i !== 1) topHalf.push(items.shift())
            else bottomHalf.push(items.shift())
            i = i - 1 === 0 ? 4 : i - 1
        }

        return [topHalf, bottomHalf]
    }

    attachLosersBracket (winnersFinals: BracketSet) {
        if (!winnersFinals) return winnersFinals

        const grandFinals = new BracketSet({
            setId: (winnersFinals.setId! * 2),
            type: "winners",
            round: winnersFinals.round!
        })

        const grandFinalsReset = new BracketSet({
            setId: (winnersFinals.setId! * 2) + 1,
            type: "winners",
            round: winnersFinals.round! + 1
        })

        this.losersRoot = this.createLosersBracket(winnersFinals)
        this.winnersRoot = grandFinals

        grandFinals.addLeftSet(winnersFinals)
        grandFinals.addRightSet(this.losersRoot)
        grandFinalsReset.addSet(grandFinals)
        grandFinals.setLosersSet(grandFinalsReset)
        winnersFinals.setLosersSet(this.losersRoot)

        return grandFinalsReset
    }

    linkLosersSets (losersBracket: BracketSet[]) {
        const numberOfRounds = losersBracket[losersBracket.length - 1].round!

        let firstTimeLosers = [] as BracketSet[]
        const winnersBracketSets = [] as unknown as BracketSet[][]
        let round = 1

        while (round <= numberOfRounds) {
            const loserSets = losersBracket.filter((node) => node.round! === round)
            const nextRoundSets = losersBracket.filter((node) => node.round! === round + 1)
            const winnersRoundSets = this.getSetsByRound(round)

            firstTimeLosers = [firstTimeLosers.splice(0), this.getSetsByRound(round)].flat()
            winnersBracketSets.push(winnersRoundSets)

            switch (round) {
                case 1:
                    const weavedSets = this.weaveItems(loserSets)
                    const topHalf = weavedSets[0]
                    const bottomHalf = weavedSets[1]
                    const round2TopHalf = nextRoundSets.length > 1 ? nextRoundSets.slice(0, nextRoundSets.length / 2) : nextRoundSets
                    const round2BottomHalf = nextRoundSets.length > 1 ? nextRoundSets.slice(nextRoundSets.length / 2) : nextRoundSets

                    while (topHalf.length) {
                        round2TopHalf.forEach((set) => { set.addSet(topHalf.shift()) })
                        round2TopHalf.reverse()
                    }

                    while (bottomHalf.length) {
                        round2BottomHalf.forEach((set) => { set.addSet(bottomHalf.shift()) })
                        round2BottomHalf.reverse()
                    }
                    break
                case numberOfRounds:
                    break
                default:
                    if (loserSets.length > nextRoundSets.length) {
                        while (loserSets.length) {
                            nextRoundSets
                                .forEach((set, index) => {
                                    set.addSet(loserSets.shift())
                                    if (!set.rightSet) set.addSet(loserSets.shift())
                                })
                        }
                    } else {
                        while (loserSets.length) nextRoundSets.forEach((set, index) => set.addSet(loserSets.shift()))
                    }
                    break
            }

            winnersRoundSets.forEach((set, index, array) => {
                set.setLosersSet(
                    losersBracket.find((loserSet) => {
                        return !loserSet.leftEntrant.entrantTag.length || !loserSet.rightEntrant.entrantTag.length
                    })
                )
            })

            round++
        }

        return losersBracket.slice(-1)[0]
    }

    createLosersBracket (winnersFinals: BracketSet) {
        const totalNumberOfPlayers = this.numberOfEntrants - 2 > 2 ? this.numberOfEntrants - 2 : 2
        const losersSets = [] as unknown as BracketSet[]
        const firstTimeLosers = this.getSetsByRound(1).length + this.getSetsByRound(2).length
        const firstTimeLosersByes = this.findHighestPowerOf2(firstTimeLosers) - firstTimeLosers

        let setId = winnersFinals.setId! + 1
        let setIdIncrement = 0
        let numberOfSets = 0
        let surplus = 0
        let round = 1
        let winnersRound = round

        while (setIdIncrement < totalNumberOfPlayers) {
            const winnersRoundSets = this.getSetsByRound(winnersRound)
            const previousRoundSets = losersSets.filter((set) => set.round! === round - 1)

            if (!winnersRoundSets.length) {
                winnersRoundSets.push(...this.getSetsByRound(winnersRound - 1))
            }

            switch (round) {
                case 1:
                    numberOfSets = (firstTimeLosers - firstTimeLosersByes) / 2
                    surplus = winnersRoundSets.length - (numberOfSets * 2)

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    break
                case 2:
                    numberOfSets = (firstTimeLosersByes + previousRoundSets.length) / 2

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    surplus = 0

                    break
                default:
                    if (this.isPowerOf2(previousRoundSets.length + winnersRoundSets.length)) {
                        numberOfSets = (previousRoundSets.length + winnersRoundSets.length) / 2
                    } else {
                        if (this.isPowerOf2(previousRoundSets.length + surplus)) {
                            numberOfSets = (previousRoundSets.length + surplus) / 2
                            surplus = winnersRoundSets.length
                        } else numberOfSets = previousRoundSets.length / 2
                    }

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    break
            }

            winnersRound++
            round++
        }

        const loserFinals = losersSets.slice(-1)[0]

        if (loserFinals.round === losersSets.slice(-2)[0].round) loserFinals.round = loserFinals.round! + 1

        return this.linkLosersSets(losersSets.flat())
    }

    getSetById(id: number, set = this.root!): BracketSet | undefined {
        let found = undefined
        if (set.setId === id) return set
        if (set.leftSet) found = this.getSetById(id, set.leftSet)
        if (set.rightSet && !found) found = this.getSetById(id, set.rightSet)
        return found
    }

    getAllWinnersSets () {
        let round = 1
        const rounds = this.calculateRounds()
        const result = []
        while (round <= rounds) {
            result.push(this.getSetsByRound(round))
            round++
        }
        return result.flat()
    }

    getSetsByRound(round: number, filters?: {
        set?: BracketSet,
        type?: "winners" | "losers"
    }): BracketSet[] {
        const {set = this.root!, type = false} = filters ? filters : {}
        const sets = [] as BracketSet[]
        if (set.round === round) sets.push(set)
        if (set.leftSet) sets.push(...this.getSetsByRound(round, {set: set.leftSet}))
        if (set.rightSet) sets.push(...this.getSetsByRound(round, {set: set.rightSet}))
        if (type) return sets.filter((game) => game.type === type)
        return sets
    }

    calculateRounds(size: number = this.numberOfEntrants) {
        let rounds = 1
        while (Math.pow(2,rounds) < size) rounds++
        return rounds
    }

    findHighestPowerOf2(threshold: number = this.numberOfEntrants) {
        if (threshold < 2) return threshold
        if (this.isPowerOf2(threshold)) return threshold
        let i = 1
        while (threshold > Math.pow(2, i) && threshold !== Math.pow(2, i)) i++
        return Math.pow(2, i)
    }

    isPowerOf2(x: number) { return (Math.log2(x) % 1 === 0) }
    addMetaData(data: any) { this.other = Object.assign(this.other, data) }
}

export default BracketEvent
