import {List, ListIcon, ListItem} from "@chakra-ui/react";
import TournamentsIcon from "../icons/TournamentsIcon";
import LogoutIcon from "../icons/LogoutIcon";
import {NavLink} from 'react-router-dom'
import CalendarIcon from "../icons/CalendarIcon";
import {useContext} from "react";
import {UserContext} from "../../contexts/main";
import LoginIcon from "../icons/LoginIcon";
import {SettingsIcon} from "@chakra-ui/icons";
import {Icon} from "@iconify/react";

// @ts-ignore
const MenuLink = ({path, children}) => {
    return (
        <NavLink
            to={`${path}`}
            style={({ isActive, isPending, isTransitioning }) => {
                return {
                    color: isActive || isPending || isTransitioning ? "#FFFFFF" : "inherit",
                    background: "gray.200",
                }
            }}>
            {children}
        </NavLink>
    )
}

const hoverStyle = {
    background: "gray.200",
    color: "gray.800",
    cursor: "pointer",
}

const Navigation = () => {
    const user = useContext(UserContext)
    return (
        <List color="gray.500">
            <ListItem
                p={2}
                alignItems="center">
                <MenuLink path="/schedule">Schedule</MenuLink>
            </ListItem>

            <ListItem
                p={2}
                alignItems="center">
                <MenuLink path="/tournaments">Tournaments</MenuLink>
                {
                    user ?
                        <List>
                          {
                            !(user.msc as TMAUser).isAdmin ||
                            <>
                              <ListItem
                                p={2}
                                alignItems="center">
                                <MenuLink path="/tournaments/create">
                                  New Tournament
                                </MenuLink>
                              </ListItem>
                              <ListItem
                                p={2}
                                alignItems="center">
                                <MenuLink path="/tournaments/manage">Manage Tournaments</MenuLink>
                              </ListItem>
                            </>
                          }
                        </List>
                        : <></>
                }
            </ListItem>

            {
                user
                    ?
                    <>
                        <ListItem
                            p={2}
                            alignItems="center">
                            <MenuLink path="/logout">Logout</MenuLink>
                        </ListItem>
                    </>
                    :
                    <>
                        <ListItem
                            p={2}
                            alignItems="center">
                            <MenuLink path="/login">Login</MenuLink>
                        </ListItem>
                    </>
            }
        </List>
    )
}

export default Navigation
