import Preloader from "../../views/Preloader";
import {Await, useLoaderData} from "react-router-dom";
import {Link, Text} from "@chakra-ui/react";
import {TournamentContext} from "../../contexts/main";
import React from "react";

const TournamentContextWrapper = (props: {
    children: any
}) => {
    const data = useLoaderData()
    const {children} = props
    return (
        <React.Suspense fallback={<Preloader/>}>
            <Await
                resolve={(data as any).tournament as Promise<Tournament>}
                errorElement={<Text>Hmm, this tournament doesn't seem to exist. <Link href="/tournaments">Check out all tournaments instead.</Link></Text>}>
                {
                    (tournament) => {
                        return (
                            <TournamentContext.Provider value={tournament}>
                                {children}
                            </TournamentContext.Provider>
                        )
                    }
                }
            </Await>
        </React.Suspense>
    )
}

export default TournamentContextWrapper
