import {Button} from "@chakra-ui/react";
import {useContext} from "react";
import {AppConfigContext} from "../../../contexts/main";
import StartggIcon from "../../icons/StartggIcon";

const StartggLogin = () => {
    const appConfig = useContext(AppConfigContext);
    const redirect = () => {
        const scopes = [
            "user.identity",
            "user.email",
            "tournament.manager",
            "tournament.reporter"
        ]
        const params = new URLSearchParams()

        params.append("response_type", "code")
        params.append("client_id", `${appConfig.START_GG_CLIENT_ID}`)
        params.append("scope", scopes.join(" "))
        params.append("redirect_uri", `${process.env.REACT_APP_S1_API_ENDPOINT}/startgg/oauth`)

        window.location.href = `https://start.gg/oauth/authorize?${params.toString()}`
    }

    return (
        <>
            <Button
                leftIcon={<StartggIcon/>}
                onClick={redirect}
                colorScheme='teal'>
                start.gg
            </Button>
        </>
    )
}

export default StartggLogin
