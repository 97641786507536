import React from "react";
import UserContextWrapper from "../../components/utility/UserContextWrapper";
import TournamentView from "../tournaments/TournamentView";
import TournamentContextWrapper from "../../components/utility/TournamentContextWrapper";
import GamesListContextWrapper from "../../components/utility/GamesListContextWrapper";
import DashboardLayout from "../../components/layout/DashboardLayout";

const TournamentPage = () => {
    return (
        <TournamentContextWrapper>
            <UserContextWrapper>
                <GamesListContextWrapper>
                    <DashboardLayout>
                        <TournamentView/>
                    </DashboardLayout>
                </GamesListContextWrapper>
            </UserContextWrapper>
        </TournamentContextWrapper>
    )
}

export default TournamentPage
