import TournamentCardsDisplay from "../components/layout/tournaments/TournamentCardsDisplay";
import TournamentsContextWrapper from "../components/utility/TournamentsContextWrapper";
import UserContextWrapper from "../components/utility/UserContextWrapper";
import React from "react";
import DashboardLayout from "../components/layout/DashboardLayout";

const HomePage = () => {
    return (
        <TournamentsContextWrapper>
            <UserContextWrapper>
                <DashboardLayout>
                    <TournamentCardsDisplay/>
                </DashboardLayout>
            </UserContextWrapper>
        </TournamentsContextWrapper>
    )
}

export default HomePage
