import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext} from "../../contexts/main";
import TournamentSignUpForm from "../forms/tournaments/TournamentSignUpForm";

const SignUpDrawer = (props: {
    isOpen: boolean,
    onSuccess: (p: Participant) => void | {},
    onClose: () => void | {}
}) => {
    const tournament = useContext(TournamentContext)
    const {isOpen, onSuccess, onClose} = props
    const firstField = React.useRef() as unknown as any
    const [isSubmitting, setIsSubmitting] = useState(false)

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            initialFocusRef={firstField}
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    Register for {tournament.attributes.name}
                </DrawerHeader>

                <DrawerBody>
                    <TournamentSignUpForm includeSubmit={false} onSuccess={(participant) => {
                        onSuccess(participant)
                        onClose()
                        setIsSubmitting(false)
                    }} onLoading={() => {
                        setIsSubmitting(true)
                    }}/>
                </DrawerBody>

                <DrawerFooter borderTopWidth='1px'>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme='green'
                        form="tournament-registration"
                        type="submit"
                        isLoading={isSubmitting}>
                        Sign up
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default SignUpDrawer
