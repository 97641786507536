import {Box, Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Stack} from "@chakra-ui/react";
import {Form} from "react-router-dom";
import React, {useState} from "react";
import {Icon} from "@iconify/react";

const LoginForm = () => {
    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const emailChangeEvent = (event: any) => setEmailAddress(event.target.value)
    const passwordChangeEvent = (event: any) => setPassword(event.target.value)

    // @ts-ignore
    const formAction = (event) => {
        event.preventDefault()
        setIsSubmitting(true)

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/entrance/login`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emailAddress, password })
        }).then(async (response) => {
            if (response.ok) {
                const jwt = await response.json()
                localStorage.setItem("token", jwt.token)
                window.history.back()
            }
        }).finally(() => setIsSubmitting(false))
    }

    return (
        <Form id="sign-up" onSubmit={formAction}>
            <Stack spacing='24px'>
                <Box>
                    <FormControl>
                        <FormLabel htmlFor='email' display="flex" dir="row" alignItems="center">
                            <Icon icon="ic:baseline-email"/> &nbsp; Email
                        </FormLabel>
                        <InputGroup>
                            <Input
                                type='email'
                                id='email'
                                placeholder='youremail@example.com'
                                onChange={emailChangeEvent}
                                required
                            />
                        </InputGroup>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl>
                        <FormLabel htmlFor='password' display="flex" dir="row" alignItems="center">
                            <Icon icon="mdi:form-textbox-password"/> &nbsp; Password
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                id='password'
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                placeholder='Enter password'
                                onChange={passwordChangeEvent}
                                required
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </Box>

                <Button
                    form="sign-up"
                    type="submit"
                    isLoading={isSubmitting}>
                    Login
                </Button>
            </Stack>
        </Form>
    )
}

export default LoginForm
