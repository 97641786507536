import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useDisclosure
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";
import {Icon} from "@iconify/react"

// @ts-ignore
function TournamentDropOutButton(props: {
    onSubmitHandler: (pID: string) => void | {}
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {onSubmitHandler} = props
    const cancelRef = React.useRef()
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const player = tournament.relationships.participants.links.data
        .find((player) => {
            if (!player.attributes.misc) return false
            const metaData = player.attributes.misc.split(";")[0]
            return metaData.user === user.usr
        })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dropPlayer = async () => {
        setIsSubmitting(true)

        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/drop-out/?participantId=${player?.id}`, {
            method: "DELETE",
            headers,
            body: JSON.stringify(player),
        }).then(async (response) => {
            if (response.ok) onSubmitHandler(await response.text())
        }).finally(() => {
            onClose()
        })
    }

    return (
        <>
            <Button onClick={onOpen} colorScheme='red'>
                <Icon icon="fluent-mdl2:leave-user" style={{marginRight: "5px"}}/>
                Leave Tournament
            </Button>

            <AlertDialog
                isOpen={isOpen}
                // @ts-ignore
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {tournament.attributes.name}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to drop out?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            {/*@ts-ignore*/}
                            <Button ref={cancelRef} onClick={onClose}>
                                Nevermind
                            </Button>
                            <Button colorScheme='red' onClick={dropPlayer} ml={3} isLoading={isSubmitting}>
                                Leave Tournament
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default TournamentDropOutButton
