import {createStylesContext, HStack, useMultiStyleConfig} from "@chakra-ui/react";
import React from "react";

const HMenu = (props: {
    size?: any,
    variant?: any,
    children?: any,
}) => {
    const { size, variant } = props
    const [StylesProvider] = createStylesContext('HMenu')
    const styles = useMultiStyleConfig('HMenu', { size, variant })

    return (
        <HStack
            gap={"0"}
            __css={styles.hmenu}
            _hover={{cursor: "pointer"}}>
            <StylesProvider value={styles}>{props.children}</StylesProvider>
        </HStack>
    )
}

export default HMenu
