import {Box, Card, CardBody, CardHeader, Heading, HStack, SimpleGrid, Text} from "@chakra-ui/react";
import React, {useContext} from "react";
import {MatchesContext, TournamentContext, UserContext} from "../../../contexts/main";
import {Icon} from "@iconify/react";
import {addGrandsResetRound, roundCall} from "../../../utility/challonge/tournamentUtils";

const MatchDisplay = () => {
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const matches = useContext(MatchesContext)
    const players = tournament.relationships.participants.links.data as unknown as Participant[]
    const player: Participant = tournament.relationships.participants.links.data.find((player: Participant) => player.attributes.misc === user.usr)
    const findPlayerInfo = (playerId: string | number) => players.find((player) => `${player.id}` === `${playerId}`)
    const playerMatches = matches.filter((match) => [match.relationships?.player1?.data.id, match.relationships?.player2?.data.id].includes(player.id))
    const completedMatches = playerMatches.filter((match) => match.attributes.state === "complete")
    const isPlayer1 = (match: Match) => match.relationships?.player1?.data.id === player?.id

    return (
        <SimpleGrid mt={4} columns={[2, null, 4]} spacing={2}>
            {
                completedMatches.map((match, index) => {
                    const opp = isPlayer1(match) ? findPlayerInfo(match.relationships?.player2?.data.id!) : findPlayerInfo(match.relationships?.player1?.data.id!)
                    return (
                        <Card variant="outline" key={index}>
                            <CardHeader pb={0}>
                                <Text fontStyle="oblique">{roundCall(addGrandsResetRound(tournament.relationships.matches.links.data), match)}</Text>
                                <Heading size='md'>
                                    <HStack>
                                        <Icon icon="emojione-monotone:vs-button"/>
                                        <Box>{opp?.attributes.name}</Box>
                                    </HStack>
                                </Heading>
                            </CardHeader>
                            <CardBody pt={1}>
                                <Text>{isPlayer1(match) ? player.attributes.name : opp?.attributes.name} {match.attributes.scores} {!isPlayer1(match) ? player.attributes.name : opp?.attributes.name}</Text>
                            </CardBody>
                        </Card>
                    )
                })
            }
        </SimpleGrid>
    )
}

export default MatchDisplay
