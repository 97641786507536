import {
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Stack
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {MatchContext, TournamentContext, UserContext} from "../../../contexts/main";
import {Form} from "react-router-dom";
import {Icon} from "@iconify/react";

const MatchReportForm = (props: {
    includeSubmit: boolean,
    onSuccess?: (matches: Matches) => void | {},
    onError?: (error: {error: string}) => void | {},
    onComplete?: () => void | {},
    onLoading?: () => void | {}
}) => {
    const {
        includeSubmit,
        onLoading,
        onSuccess,
        onError,
        onComplete
    } = props
    const user = useContext(UserContext)
    const tournament = useContext(TournamentContext)
    const match = useContext(MatchContext)
    const isMatchComplete = match.attributes.state === "complete"
    const players = tournament.relationships.participants.links.data as unknown as Participants
    const findPlayerInfo = (playerId: string | number) => players.find((player) => `${player.id}` === `${playerId}`)
    const player = players.find((player) => user ? player.attributes.misc === user.usr : false)
    const player1 = !!match.attributes.pointsByParticipant[0].participantId ? findPlayerInfo(match.attributes.pointsByParticipant[0].participantId) : undefined
    const player2 = !!match.attributes.pointsByParticipant[1].participantId ? findPlayerInfo(match.attributes.pointsByParticipant[1].participantId) : undefined
    const isMissingPlayer = player1 === undefined || player2 === undefined
    const isMissingBothPlayers = match.relationships.player1 === undefined && match.relationships.player2 === undefined
    const isPlayerMatch = () => {
        if (player === undefined) return false
        return !isMissingBothPlayers ?
            match.attributes.pointsByParticipant
                .map((participant) => participant.participantId || '')
                .includes(+player.id)
            : false
    }
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [player1Score, setPlayer1Score] = useState(isMatchComplete ? +match.attributes.scoreInSets![0][0] : 0)
    const [player2Score, setPlayer2Score] = useState(isMatchComplete ? +match.attributes.scoreInSets![0][1] : 0)
    const onPlayer1ScoreChange = (value: any) => setPlayer1Score(value)
    const onPlayer2ScoreChange = (value: any) => setPlayer2Score(value)
    const isInvalidScore = `${player1Score}` === `${player2Score}`

    // @ts-ignore
    const formAction = () => {
        setIsSubmitting(true)
        if (isMissingPlayer) {
            setIsSubmitting(false)
            if (onComplete) onComplete()
            return
        }
        if (onLoading) onLoading()

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/matches/${match.id}/${player!.id}/report`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify([
                {
                    participant_id: player1!.id,
                    score_set: `${player1Score}`,
                    advancing: player1Score > player2Score,
                },
                {
                    participant_id: player2!.id,
                    score_set: `${player2Score}`,
                    advancing: player1Score < player2Score,
                }
            ] as MatchReport)
        }).then(async (response) => {
            if (response.ok && onSuccess) onSuccess(await response.json())
            else { if (onError) onError(await response.json()) }
        }).finally(() => {
            if (onComplete) onComplete()
            setIsSubmitting(false)
        })
    }

    return (
        <Form id="report-score" onSubmit={formAction}>
            <Stack spacing='24px'>
                <HStack>
                    <FormControl isInvalid={isInvalidScore}>
                        <FormLabel>{player1 ? player1.attributes.name : "Pending..."}</FormLabel>
                        <NumberInput
                            isDisabled={isMatchComplete || isMissingPlayer || isMissingBothPlayers || !isPlayerMatch()}
                            defaultValue={player1Score}
                            min={0} max={99}
                            onChange={onPlayer1ScoreChange}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>

                    <Box fontSize="1.2em" mx={2} mt={8}>
                        <Icon icon="emojione-monotone:vs-button"/>
                    </Box>

                    <FormControl isInvalid={isInvalidScore}>
                        <FormLabel>{player2 ? player2.attributes.name : "Pending..."}</FormLabel>
                        <NumberInput
                            isDisabled={isMatchComplete || isMissingPlayer || isMissingBothPlayers || !isPlayerMatch()}
                            defaultValue={player2Score}
                            min={0} max={99}
                            onChange={onPlayer2ScoreChange}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </HStack>

                {
                    includeSubmit && isPlayerMatch() &&
                        <Center>
                            <Button
                                colorScheme='green'
                                form="report-score"
                                onClick={formAction}
                                isDisabled={isInvalidScore || isMissingPlayer || isMissingBothPlayers || !isPlayerMatch()}
                                isLoading={isSubmitting}>
                                Report Scores
                            </Button>
                        </Center>
                }
            </Stack>
        </Form>
    )
}

export default MatchReportForm
