import Login from "./views/Login";
import {getChatRooms, getGamesList, verifyJWT} from "./utility";
import {defer} from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy";
import ComingSoon from "./views/ComingSoon";
import NewAccountForm from "./components/forms/NewAccountForm";
import HomePage from "./views/HomePage";
import DashboardLayout from "./components/layout/DashboardLayout";
import TournamentManagement from "./views/admin/TournamentManagement";
import NewTournament from "./views/admin/NewTournament";
import EditTournament from "./views/admin/EditTournament";
import Tournaments from "./views/pages/Tournaments";
import TournamentPage from "./views/pages/TournamentPage";

const APP_ROUTES = [
    {
        path: "/",
        element: <HomePage />,
        loader: async () => {
            const params = new URLSearchParams()
            params.append("page", `1`)
            params.append("per_page", `10`)
            params.append("state", ``)
            params.append("include_participants", ``)
            return defer({
                user: await verifyJWT(),
                tournaments: fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        }
    },
    {
        path: "/sign-up",
        element: <NewAccountForm />
    },
    {
        path: "/schedule",
        element: <DashboardLayout><ComingSoon /></DashboardLayout>,
        loader: async () => {
            const params = new URLSearchParams()
            params.append("page", `1`)
            params.append("per_page", `10`)
            params.append("state", ``)
            params.append("include_participants", ``)
            return defer({
                user: await verifyJWT(),
                tournaments: await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        }
    },
    {
        path: "/tournaments",
        element: <Tournaments/>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
                tournaments: fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?include_participants`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        },
    },
    {
        path: '/tournaments',
        element: <TournamentPage/>,
        //@ts-ignore
        loader: async ({params}) => {
            const tournamentPromise = fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${params.tournamentId}`).then((res) => res.json())
            return defer({
                user: await verifyJWT(),
                tournament: tournamentPromise,
                gamesList: await getGamesList(),
                chatRooms: await getChatRooms(),
            })
        },
        children: [{ path: ':tournamentId' }]
    },
    {
        path: "/tournaments/create",
        element: <NewTournament/>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
                gamesList: await getGamesList(),
            })
        },
    },
    {
        path: '/tournaments/edit',
        element: <EditTournament/>,
        //@ts-ignore
        loader: async ({params}) => {
            const tournamentPromise = fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${params.tournamentId}`).then((res) => res.json())
            return defer({
                user: await verifyJWT(),
                tournament: await tournamentPromise,
                gamesList: await getGamesList(),
            })
        },
        children: [{ path: ':tournamentId' }]
    },
    {
        path: "/tournaments/manage",
        element: <TournamentManagement/>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
                tournaments: fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?include_participants`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        },
    },
    {
        path: "/login",
        element: <Login/>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
            })
        },
    },
    {
        path: "/logout",
        element: <></>,
        loader: () => {
            localStorage.removeItem("token")
            window.location.href = `${window.location.protocol}//${window.location.host}`
            return null
        }
    },
    {
        path: "/tos",
        element: <ComingSoon/>
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy/>
    },
]

export default APP_ROUTES
