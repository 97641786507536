import React from "react";
import {useLoaderData} from "react-router-dom";
import {ChatRoomsContext} from "../../contexts/main";

const ChatRoomsContextWrapper = ({
    // @ts-ignore
    children
}) => {
    const data = useLoaderData()
    return (
        <ChatRoomsContext.Provider value={(data as any).chatRooms}>
            {children}
        </ChatRoomsContext.Provider>
    )
}

export default ChatRoomsContextWrapper
