import {Badge} from "@chakra-ui/react";
import React from "react";
import {format} from "date-fns";
import BracketEntrant from "./classes/bracket/BracketEntrant";

export const verifyJWT = () => {
    const url = new URL(window.location.href)
    const jwt = url.searchParams.get("jwt")
    if (jwt) {
        localStorage.setItem("token", jwt)
        window.location.replace(`${window.location.protocol}//${window.location.host}`)
    }

    return fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/verify-jwt`, {
        method: "GET",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        credentials: "include",
    }).then(async (res) => {
        console.log(document.cookie)
        if (res.ok) {
            const user = await res.json() as unknown as AuthenticatedUser
            return ({
                ...user,
                msc: JSON.parse(user.msc as string)
            })
        }
        else return undefined
    })
}

export const sortSeeds = (a: BracketEntrant, b: BracketEntrant) => {
    if (a.entrantID < b.entrantID) { // a is less than b by some ordering criterion
        return -1
    } else if (a.entrantID > b.entrantID) { // a is greater than b by the ordering criterion
        return 1
    }
    // a must be equal to b
    return 0
}

export const getGamesList = () => {
    return fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/games-list`, {
        method: "GET",
        credentials: "include",
    }).then((res) => res.json())
}

export const getChatRooms = () => {
    return fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/chat-rooms`, {
        method: "GET",
        credentials: "include",
    }).then((res) => res.json())
}

export const getStatusBadge = (status: string) => {
    switch (status.toLowerCase()) {
        case "complete":
            return <Badge colorScheme="green">{status}</Badge>
        case "checking_in":
            return <Badge colorScheme="yellow">{status}</Badge>
        case "pending":
            return <Badge colorScheme="blue">{status}</Badge>
        case "underway":
            return <Badge colorScheme="red">{status}</Badge>
        default:
            return <Badge>{status}</Badge>
    }
}

export const formatTime = (timestamp: string | number | Date) => {
    return format(new Date(timestamp), "MMM do, yyyy @ hh:mm aa")
}

const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
]);
export const formatOrdinals = (n: number) => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix}`;
};

export const decodeUser = (user: AuthenticatedUser) => {
    const {api, msc, usr} = user
    switch (api) {
        case "discord":
            const discordUser = msc as DiscordUser
            return ({
                // avatar: discordUser.user.avatar && `https://cdn.discordapp.com/avatars/${discordUser.user.id}/${discordUser.user.avatar}`,
                name: discordUser.user.global_name,
            })
        case "challonge":
            const challongeUser = msc as ChallongeUser
            return ({
                // avatar: challongeUser.data.attributes.imageUrl.length && challongeUser.data.attributes.imageUrl,
                name: challongeUser.data.attributes.username,
            })
        case "start.gg":
            const startggUser = msc as StartggUser
            return ({
                // avatar: startggUser.images.length && startggUser.images[startggUser.images.length - 1].url,
                name: startggUser.name,
            })
        case "twitter":
            const twitterUser = msc as TwitterUser
            return ({
                // avatar: false,
                name: twitterUser.data.name,
            })
        case "tma":
            return ({
                // avatar: false,
                name: usr,
            })
        default:
            return ({
                // avatar: false,
                name: `${usr} (Guest User)`,
            })
    }
}
