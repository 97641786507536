export const roundCall = (matches: Matches, currentMatch: Match) => {
    const isWinners = currentMatch.attributes.round! > 0
    const winners = matches.filter((match) => match.attributes.round! > 0)
    const losers = matches.filter((match) => match.attributes.round! < 0)
    const winnersIds = winners.map((match) => match.id)
    const losersIds = losers.map((match) => match.id)
    const bracketReset = checkBracketReset(matches)

    if (isWinners && bracketReset) {
        if (winnersIds.indexOf(currentMatch.id) === winnersIds.length - 1) return `Grand Finals Reset`
        if (winnersIds.indexOf(currentMatch.id) === winnersIds.length - 2) return `Grand Finals`
        else if (winnersIds.indexOf(currentMatch.id) === winnersIds.length - 3) return `Winners Semifinals`
        else return `Winners Round ${currentMatch.attributes.round}`
    } else if (isWinners && !bracketReset) {
        if (winnersIds.indexOf(currentMatch.id) === winnersIds.length - 1 && currentMatch.attributes.round !== 1) return `Grand Finals`
        else if (winnersIds.indexOf(currentMatch.id) === winnersIds.length - 2 && currentMatch.attributes.round !== 1) return `Winners Semifinals`
        else return `Winners Round ${currentMatch.attributes.round}`
    } else if (!isWinners) {
        if (losersIds.indexOf(currentMatch.id) === losersIds.length - 1) return `Losers Finals`
        else return `Losers Round ${Math.abs(currentMatch.attributes.round!)}`
    }
}

export const findPlayerInfo = (playerId: string | number, players: Participants) => players.find((player) => `${player.id}` === `${playerId}`)

export const checkBracketReset = (matches: Matches) => {
    if (!matches.length || matches[matches.length - 1].attributes.state !== "complete") return false
    const lastMatch = matches[matches.length - 1]
    const secondToLastMatch = matches[matches.length - 2]
    return [
        `${lastMatch.relationships?.player1?.data.id}`,
        `${lastMatch.relationships?.player2?.data.id}`
    ].includes(`${secondToLastMatch.attributes.winners}`) && secondToLastMatch.attributes.round! > 0
}

export const addGrandsResetRound = (matches: Matches) => {
    return matches
        .map((match) => {
            if (matches.indexOf(match) === matches.length - 1) {
                return ({
                    attributes: Object.assign({...match.attributes}, {
                        round: match.attributes.round! + 1
                    }),
                    id: match.id,
                    relationships: match.relationships,
                    type: match.type,
                    included: match.included,
                } as Match)
            }
            else return match
        })
}

export const checkIfPlayerMatch = (user: AuthenticatedUser, match: Match, players: Participants) => {
    if (user === undefined) return false
    const playerIds = match.attributes.pointsByParticipant.map((participant) => participant.participantId)
    return players.some((player) => player.attributes.misc === user.usr && playerIds.includes(+player.id))
}
