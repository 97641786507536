import {useLoaderData} from "react-router-dom";
import {GamesListContext} from "../../contexts/main";
import DashboardLayout from "../../components/layout/DashboardLayout";
import AdminWrapper from "../../components/utility/AdminWrapper";
import {Container} from "@chakra-ui/react";
import TournamentsCreateForm from "../../components/forms/tournaments/TournamentsCreateForm";

const HomePage = () => {
    const data = useLoaderData() as any
    const {gamesList} = data
    return (
        <AdminWrapper>
            <DashboardLayout>
                <Container p={4}>
                    <GamesListContext.Provider value={gamesList}>
                        <TournamentsCreateForm/>
                    </GamesListContext.Provider>
                </Container>
            </DashboardLayout>
        </AdminWrapper>
    )
}

export default HomePage
