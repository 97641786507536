import {Box, Button, FormControl, GridItem, Input, SimpleGrid} from "@chakra-ui/react";
import React, {useContext, useRef, useState} from "react";
import {Icon} from "@iconify/react";
import {ChatRoomsContext, TournamentContext, UserContext} from "../../../contexts/main";
// @ts-ignore
import client from "sails.io.js"
import socket from "socket.io-client"
import {Field, Form, Formik} from "formik";
import WidgetBot from "@widgetbot/react-embed";
import Client from "@widgetbot/embed-api/dist/client"

const ChatBox = (props: {
    useInput: boolean,
}) => {
    const { useInput } = props
    const user = useContext(UserContext)
    const tournament = useContext(TournamentContext)
    const chatRooms = useContext(ChatRoomsContext)
    const [joined, setJoined] = useState(false)
    // @ts-ignore
    const io = socket.sails ? socket : client(socket)
    const sailsSocket = useRef(undefined as unknown as any)
    const chatRoom = chatRooms.find((room) => room.name.includes(`${tournament.id}`))
    const threadId = chatRoom ? chatRoom.id : process.env.REACT_APP_DISCORD_TOURNAMENT_DEFAULT_THREAD
    const channelId = process.env.REACT_APP_DISCORD_FORUM_CHANNEL_ID
    const [discordChat, setDiscordChat] = useState(null as unknown as Client)

    // @ts-ignore
    io.sails.autoConnect = false

    // @ts-ignore
    // IMPORTANT DO NOT DELETE
    // socket.initialConnectionHeaders = { __sails_io_sdk_version: '1.2.1', __sails_io_sdk_platform: 'node', __sails_io_sdk_language: 'javascript' };

    const sendMessage = async (values: { message: string }) => {
        sailsSocket.current.post(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/chat/post`, {content: values.message, user})
        values.message = ""
    }

    const chatEventSetup = () => {
        sailsSocket.current.on('disconnect', () => {
            sailsSocket.current.off('join')
            sailsSocket.current.off('post')
        })

        sailsSocket.current.on('join')

        sailsSocket.current.on('exit')

        sailsSocket.current.on('post')
    }

    const createSailsSocket = () => {
        sailsSocket.current = io.sails.connect(`${process.env.REACT_APP_S1_BASE_URI}`)
        sailsSocket.current.on('connect', () => {
            chatEventSetup()
            sailsSocket.current.put(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/chat/join`, user, (data: any) => {
                if (data !== "Bad Request") setJoined(true)
            })
        })
    }

    return (
        <Box w={"100%"}>
            <Box mb={4}>
                {/*@ts-ignore*/}
                <WidgetBot
                    server={process.env.REACT_APP_DISCORD_CHAT_SERVER}
                    channel={channelId}
                    thread={threadId}
                    height={"800px"}
                    width={"100%"}
                    onAPI={(api) => { setDiscordChat(api)}}
                />
            </Box>
            {
                useInput &&
                  <Formik initialValues={{
                      message: "",
                  }} onSubmit={sendMessage}>
                    <Form>
                      <SimpleGrid flexGrow={1} columns={5} spacing={5} w={"100%"}>
                        <GridItem colSpan={4}>
                          <Field name={"message"}>
                              {
                                  // @ts-ignore
                                  ({field, form}) => (
                                      <FormControl isInvalid={form.errors.name && form.touched.name}>
                                          <Input
                                              {...field}
                                              autoComplete="off"
                                              isRequired
                                              variant='filled'
                                              isDisabled={!joined}
                                              placeholder={`Type your message to ${tournament.attributes.name}`} />
                                      </FormControl>
                                  )
                              }
                          </Field>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Button
                            mr={2}
                            type={'submit'}
                            colorScheme={"blue"}
                            isDisabled={!joined}
                            rightIcon={<Icon fontSize="1.5em" icon="fluent:send-24-filled"/>}>
                            Send
                          </Button>
                            {
                                joined ?
                                    <Button
                                        onClick={() => {
                                            sailsSocket.current.put(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/chat/exit`, user, () => {
                                                if (sailsSocket.current.isConnected()) sailsSocket.current.disconnect()
                                                setJoined(false)
                                            })
                                        }}
                                        colorScheme={"red"}
                                        isDisabled={!joined || tournament.attributes.state === "complete"}
                                        leftIcon={<Icon fontSize="1.5em" icon="pepicons-pop:leave"/>}>
                                        Leave
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => {
                                            if (sailsSocket.current) sailsSocket.current.reconnect()
                                            else if (!sailsSocket.current) createSailsSocket()
                                            else if (!sailsSocket.current.isConnected()) createSailsSocket()
                                        }}
                                        isDisabled={tournament.attributes.state === "complete"}
                                        colorScheme={"green"}
                                        leftIcon={<Icon fontSize="1.5em" icon="gg:enter"/>}>
                                        Join
                                    </Button>
                            }
                        </GridItem>
                      </SimpleGrid>
                    </Form>
                  </Formik>
            }
        </Box>
    )
}

export default ChatBox
