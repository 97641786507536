import React, {useContext} from "react";
import {UserContext} from "../../contexts/main";
import {Avatar, HStack, Text} from "@chakra-ui/react";

const LoggedInUser = (props: {
    avatarOnly?: boolean
}) => {
    const {avatarOnly} = props
    const user = useContext(UserContext)

    const decodeUser = (userObj: string) => {
        switch (userObj.toLowerCase()) {
            case "discord":
                const discordUser = user.msc as DiscordUser
                return ({
                    avatar: <Avatar src={`https://cdn.discordapp.com/avatars/${discordUser.user.id}/${discordUser.user.avatar}`} name={discordUser.user.global_name} />,
                    name: discordUser.user.global_name,
                })
            case "challonge":
                const challongeUser = user.msc as ChallongeUser
                return ({
                    avatar: <Avatar src={challongeUser.data.attributes.imageUrl} name={challongeUser.data.attributes.username} />,
                    name: challongeUser.data.attributes.username,
                })
            case "start.gg":
                const startggUser = user.msc as StartggUser
                return ({
                    avatar: startggUser.images.length ?
                        <Avatar src={startggUser.images[startggUser.images.length - 1].url} name={startggUser.name} />
                        : <Avatar name={startggUser.name} />,
                    name: startggUser.name,
                })
            case "twitter":
                const twitterUser = user.msc as TwitterUser
                return ({
                    avatar: <Avatar name={twitterUser.data.name} />,
                    name: twitterUser.data.name,
                })
            case "tma":
                const tmaUser = user.msc as TMAUser
                return ({
                    avatar: <Avatar name={user.usr} />,
                    name: `${user.usr} ${tmaUser.isAdmin ? '(Admin)' : ''}`,
                })
            default:
                return ({
                    avatar: <Avatar name={user.usr} />,
                    name: `${user.usr} (Guest User)`,
                })
        }
    }

    return (
        <>
            {
                !!user ?
                    !avatarOnly ?
                        <HStack p={2} alignItems="center">
                            {decodeUser(user.api)!.avatar}
                            <Text mx={4} color="gray.200">{decodeUser(user.api)!.name}</Text>
                        </HStack> : decodeUser(user.api)!.avatar
                    : <></>
            }
        </>
    )
}

export default LoggedInUser
