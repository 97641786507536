import {Alert, AlertIcon, HStack, Text} from "@chakra-ui/react";
import React, {useContext} from "react";
import {MatchesContext, TournamentContext, UserContext} from "../../../contexts/main";
import {formatOrdinals} from "../../../utility";
import {addGrandsResetRound, roundCall} from "../../../utility/challonge/tournamentUtils";

const MatchStatus = () => {
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const matches = useContext(MatchesContext)
    const players = tournament.relationships.participants.links.data as unknown as Participants
    const player = players.find((player) => player.attributes.misc === user.usr)
    const findPlayerInfo = (playerId: string | number) => players.find((participant) => `${participant.id}` === `${playerId}`)
    const isPlayer1 = (match: Match) => player ? match.relationships?.player1?.data.id === player?.id : false

    const MatchInfo = () => {
        if (player === undefined) return <></>
        const playerMatches = matches.filter((match) => [match.relationships?.player1?.data.id, match.relationships?.player2?.data.id].includes(player.id))
        const completedRun = playerMatches.every((match) => match.attributes.state === "complete")
        const currentMatch = matches.find((match) => match.attributes.state === "open"
            && [match.relationships?.player1?.data.id, match.relationships?.player2?.data.id].includes(player?.id))
        const findOpponent = (() => isPlayer1(currentMatch!) ? findPlayerInfo(currentMatch!.relationships!.player2!.data.id!) : findPlayerInfo(currentMatch!.relationships!.player1!.data.id!))

        if (currentMatch) {
            return (
                <Alert
                    status='info'
                    variant='solid'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                >
                    <AlertIcon />
                    <Text fontWeight="bold" fontSize="large">YOUR NEXT OPPONENT</Text>
                    <Text fontStyle="oblique">{roundCall(addGrandsResetRound(tournament.relationships.matches.links.data), currentMatch)}</Text>
                    <Text lineHeight={1.2} fontSize="xxx-large">{findOpponent()!.attributes.name}</Text>
                </Alert>
            )
        }

        else if (completedRun && tournament.attributes.state === "complete") {
            return (
                <Alert
                    status='info'
                    variant='solid'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                >
                    <AlertIcon />
                    <Text fontWeight="bold" fontSize="medium">YOU PLACED</Text>
                    <HStack lineHeight={1.2} fontSize="xx-large">
                        <Text>{formatOrdinals(player!.attributes.finalRank!)}</Text>
                    </HStack>
                </Alert>
            )
        }

        return <></>
    }

    return (
        <>
            { player !== undefined && <MatchInfo/> }
        </>
    )
}

export default MatchStatus
