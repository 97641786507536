import {Await, useLoaderData} from "react-router-dom";
import {Select, Skeleton} from "@chakra-ui/react";
import {GamesListContext} from "../../contexts/main";
import React from "react";

const GamesFilterContextWrapper = (props: {
    gameFilterEvent: (gameId: string) => void | {},
}) => {
    const data = useLoaderData()
    const { gameFilterEvent } = props
    return (
        <React.Suspense fallback={<Skeleton w="100%" h="50px"/>}>
            <Await
                // @ts-ignore
                resolve={(data as any).gamesList as Promise}
                errorElement={<></>}>
                {
                    (gamesList: GamesList) => {
                        return (
                            <GamesListContext.Provider value={gamesList}>
                                <Select my={4} px={2} borderColor={"gray.900"} placeholder='All Games' onChange={(event) => {
                                    gameFilterEvent(event.target.options[event.target.selectedIndex].value)
                                }}>
                                    { gamesList.map((game) => (<option key={game.game_id} value={game.game_id}>{game.game_name}</option>)) }
                                </Select>
                            </GamesListContext.Provider>
                        )
                    }
                }
            </Await>
        </React.Suspense>
    )
}

export default GamesFilterContextWrapper
