import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Button, useDisclosure
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext} from "../../contexts/main";

const DeleteTournamentModal = (props: {
    isOpen: boolean,
    onSubmit: () => {},
    onClose: () => void
}) => {
    const cancelRef = React.useRef()
    const {isOpen, onSubmit, onClose} = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const tournament = useContext(TournamentContext)

    return (
        <AlertDialog
            isOpen={isOpen}
            // @ts-ignore
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {tournament.attributes.name}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure you want to delete this?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        {/*@ts-ignore*/}
                        <Button ref={cancelRef} onClick={onClose}>
                            Nevermind
                        </Button>
                        <Button colorScheme='red' onClick={onSubmit} ml={3} isLoading={isSubmitting}>
                            Delete Tournament
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default DeleteTournamentModal
