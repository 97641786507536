import {Box, Text} from "@chakra-ui/react";
import React, {useContext} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";
import BracketEvent from "../../../utility/classes/bracket/BracketEvent";
import BracketEntrant from "../../../utility/classes/bracket/BracketEntrant";

const Bracket = (props: {
    onMatchClick?: (match: Match) => void | {}
}) => {
    const tournament = useContext(TournamentContext)
    // const user = useContext(UserContext)
    const players = tournament.relationships.participants.links.data as unknown as Participant[]
    const baseColor = "#FFF"
    const bracket = new BracketEvent({
        numberOfEntrants: players.length,
        entrants: players.map((player) => new BracketEntrant({
            entrantID: player.attributes.seed,
            initialSeed: player.attributes.seed,
            name: player.attributes.name,
            other: player.attributes
        })),
        layout: tournament.attributes.tournamentType
    })

    const DrawBracketSet = (set = bracket.root) => {
        if (!set) return <>No bracket provided.</>
        const hasChildren = set.leftSet || set.rightSet
        const matchNumberBG = "#ff6200"
        const matchNumberWidth = 30
        const height = 25
        const margin = 2
        const textPadding = 15
        const baseHorizontalStyle = {
            content: '""',
            position: "absolute",
            backgroundColor: "#fff",
            width: "50%",
            height: "0",
            borderBottom: `2px solid ${baseColor}`
        }
        const baseVerticalStyle = {
            content: '""',
            position: "absolute",
            backgroundColor: "#fff",
            height: !set.isOnlyChild() ? 0 : `${(height)}px`,
            width: "0",
            borderRight: `2px solid ${baseColor}`
        }
        const topPositioning = {top: "50%", right: `-50%`}
        const bottomPositioning = {bottom: "50%", right: `-50%`}
        let nextLeftChild = <Box h={height * 2} w={"100%"} mx={2} mb={-(height * 2)}></Box>
        let nextRightChild = <Box h={height  * 2} w={"100%"} mx={2} mt={-(height * 2)}></Box>

        if (hasChildren) {
            if (set.leftSet) nextLeftChild = DrawBracketSet(set.leftSet)
            if (set.rightSet) nextRightChild = DrawBracketSet(set.rightSet)
        }

        const getVerticalLineDisplay = () => {
            const hasParent = !!set.parentSet
            switch (true) {
                case !hasParent:
                    return ({display: "none"})
                default:
                    return ({display: "block"})
            }
        }

        const getHorizontalLineDisplay = () => {
            const hasParent = !!set.parentSet
            switch (true) {
                case !hasParent:
                    return ({display: "none"})
                default:
                    return ({display: "block"})
            }
        }

        return (
            <Box
                display={"flex"}
                flexDir={"row-reverse"}
                alignItems={"center"}
                mx={margin}>
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    pos={"relative"}
                    zIndex={1}
                    _before={Object.assign(baseHorizontalStyle, set.isLeftChild() ? {...topPositioning, ...getHorizontalLineDisplay()} : {...bottomPositioning, ...getHorizontalLineDisplay()})}
                    _after={Object.assign(baseVerticalStyle, set.isLeftChild() ? {...topPositioning, ...getVerticalLineDisplay()} : {...bottomPositioning, ...getVerticalLineDisplay()})}>
                    <Text
                        my={margin}
                        pos={"absolute"} zIndex={3}
                        top={"0"} left={`-${matchNumberWidth}px`}
                        fontWeight={"bold"} align={"center"}
                        h={height * 2} minW={`${matchNumberWidth}px`}
                        backgroundColor={matchNumberBG}
                        border={`2px solid ${baseColor}`} borderTopLeftRadius={"7px"} borderBottomLeftRadius={"7px"}>
                        <Box h={"100%"} w={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            {set.setId}
                        </Box>
                    </Text>
                    <Box
                        pr={`${textPadding}px`} pl={`${textPadding / 2}px`}
                        h={height} minW={`150px`}
                        display={"flex"}
                        alignItems={"center"}
                        backgroundColor={baseColor}
                        mt={margin}
                        borderTopRightRadius={"7px"}>
                        <Text whiteSpace={"nowrap"} pos={"relative"} zIndex={1}>{set.leftEntrant && set.leftEntrant.entrantTag}</Text>
                    </Box>
                    <Box
                        pr={`${textPadding}px`} pl={`${textPadding / 2}px`}
                        h={height} minW={`125px`}
                        display={"flex"}
                        alignItems={"center"}
                        backgroundColor={baseColor}
                        mb={margin}
                        borderBottomRightRadius={"7px"}>
                        <Text whiteSpace={"nowrap"} pos={"relative"} zIndex={1}>{set.rightEntrant && set.rightEntrant.entrantTag}</Text>
                    </Box>
                </Box>
                {
                    hasChildren &&
                    <Box display={"flex"} flexDir={"column"} mr={`${matchNumberWidth}px`} zIndex={0}>
                        {nextLeftChild}
                        {nextRightChild}
                    </Box>
                }
            </Box>
        )
    }

    return (
        <Box pos={"relative"} w={"100%"} display={"flex"} overflow={"scroll"}>{DrawBracketSet(bracket.root)}</Box>
    )
}

export default Bracket
