import React from "react";
import MatchHistory from "./MatchHistory";
import NextMatch from "./MatchStatus";

const MatchDisplay = () => {
    return (
        <>
            <NextMatch/>
            <MatchHistory/>
        </>
    )
}

export default MatchDisplay
