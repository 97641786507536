import {
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast
} from "@chakra-ui/react";
import React, {useContext} from "react";
import MatchReportForm from "../forms/tournaments/MatchReportForm";
import {MatchContext, TournamentContext} from "../../contexts/main";
import {roundCall} from "../../utility/challonge/tournamentUtils";

const ReportScoreModal = (props: {
    isOpen: boolean,
    onOpen: () => void | {},
    onClose: () => void | {},
    onSuccess?: (matches: Matches) => void | {}
}) => {
    const tournament = useContext(TournamentContext)
    const { onSuccess, isOpen, onClose } = props
    const match = useContext(MatchContext)
    const isMatchComplete = match.attributes.state !== "complete"
    const toast = useToast()

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Center>{roundCall(tournament.relationships.matches.links.data, match)}</Center>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={7}>
                        <MatchReportForm
                            onSuccess={(matches) => {
                                if (onSuccess) onSuccess(matches)
                            }}
                            onError={(response) => {
                                toast({
                                    title: '⚠️ Error',
                                    description: response.error,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                })
                            }}
                            onComplete={onClose}
                            includeSubmit={isMatchComplete}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ReportScoreModal
