import TournamentsList from "../../components/layout/tournaments/TournamentsList";
import DashboardLayout from "../../components/layout/DashboardLayout";
import AdminWrapper from "../../components/utility/AdminWrapper";
import TournamentsContextWrapper from "../../components/utility/TournamentsContextWrapper";

const TournamentManagementPage = () => {
    return (
        <AdminWrapper>
            <TournamentsContextWrapper>
                <DashboardLayout>
                    <TournamentsList/>
                </DashboardLayout>
            </TournamentsContextWrapper>
        </AdminWrapper>
    )
}

export default TournamentManagementPage
