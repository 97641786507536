import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Stack, useToast
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";
import {Form} from "react-router-dom";

// @ts-ignore
const TournamentSignUpForm = (props: {
    onSuccess: (p: Participant) => void | {},
    includeSubmit: boolean,
    onLoading?: () => void | {}
}) => {
    const {onSuccess, includeSubmit, onLoading} = props
    const firstField = React.useRef() as unknown as any
    const user = useContext(UserContext)
    const [name, setName] = useState(user.usr)
    const [twitterHandle, setTwitterHandle] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const tournament = useContext(TournamentContext)
    const nameChangeEvent = (event: any) => setName(event.target.value)
    const twitterHandleEvent = (event: any) => setTwitterHandle(event.target.value)
    const toast = useToast()

    const signUp = () => {
        return new Promise(async (resolve, reject) => {
            const {api, usr} = user
            const request = await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/sign-up`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({name, misc: `${usr};${twitterHandle};${api}`})
            })

            if (request.ok) {
                const data = await request.json()
                onSuccess(data)
                resolve(data)
            }
            else reject()
            setIsSubmitting(false)
        })
    }

    // @ts-ignore
    const formAction = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        if (onLoading) onLoading()


        toast.promise(signUp(), {
            success: { title: `Success!`, description: `You've been added to ${tournament.attributes.name}.`},
            error: { title: `Failed to join. Please try again.`, description: ''},
            loading: { title: `Submitting...`, description: ''},
        })
    }

    return (
        <Form id="tournament-registration" onSubmit={formAction}>
            <Stack spacing='24px'>
                <Box mt={8}>
                    <FormControl>
                        <FormLabel htmlFor='username'>Name</FormLabel>
                        <Input
                            ref={firstField}
                            id='username'
                            value={name}
                            onChange={nameChangeEvent}
                            disabled={true}
                        />
                        {/* It is important that the Label comes after the Control due to css selectors */}
                        <FormHelperText>Your display name for the tournament</FormHelperText>
                        <FormErrorMessage>You display name is invalid</FormErrorMessage>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl>
                        <FormLabel htmlFor='twitter'>Twitter Handle</FormLabel>
                        <InputGroup>
                            <InputLeftAddon>@</InputLeftAddon>
                            <Input
                                type='input'
                                id='twitter'
                                placeholder='YourTwitterHandleHere'
                                onChange={twitterHandleEvent}
                            />
                        </InputGroup>
                        <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>

                {
                    includeSubmit ?
                        <Button
                            colorScheme='green'
                            form="tournament-registration"
                            type="submit"
                            isLoading={isSubmitting}
                        >
                            Sign up
                        </Button>
                        :
                        <></>
                }
            </Stack>
        </Form>
    )
}

export default TournamentSignUpForm
