import TournamentSignUpForm from "../../forms/tournaments/TournamentSignUpForm";
import {Box, Center} from "@chakra-ui/react";
import TournamentDropOutButton from "../../buttons/tournament/TournamentDropOutButton";
import React, {useContext, useState} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";

const TournamentRegistration = (props: {
    onRegister?: (p: Participant) => void | {},
    onDrop?: (pId: string) => void | {}
}) => {
    const { onRegister, onDrop } = props
    const user = useContext(UserContext)
    const tournament = useContext(TournamentContext)
    const [isRegistered, setRegistered] =
        useState(
            tournament.relationships.participants.links.data
            .some((player) => {
                if (!player.attributes.misc) return false
                const metaData = player.attributes.misc.split(";")[0]
                return metaData.user === user.usr
            })
        )
    const isComplete = tournament.attributes.state.toLowerCase() === "complete"
    const isInProgress = tournament.attributes.state.toLowerCase() === "underway" || tournament.attributes.state.toLowerCase() === "awaiting_review"
    const isRegistrationOpen = !isInProgress && !isComplete

    return (
        <>
            {
                isRegistrationOpen ?
                    !isRegistered ?
                        <TournamentSignUpForm onSuccess={(participant) => {
                            if (onRegister) onRegister(participant)
                            setRegistered(true)
                        }} includeSubmit={true}/>
                        : <Box my={4} display="flex" justifyContent="center">
                            <TournamentDropOutButton onSubmitHandler={(participantId) => {
                                if (onDrop) onDrop(participantId)
                                setRegistered(false)
                            }}/>
                        </Box>
                    : <Center>Registration is closed.</Center>
            }
        </>
    )
}

export default TournamentRegistration
