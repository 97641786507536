import {Box, Button, ButtonGroup, Stack, Text, useDisclosure, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import TournamentCard from "./TournamentCard";
import SignUpDrawer from "../../overlays/SignUpDrawer";

import UserContextWrapper from "../../utility/UserContextWrapper";
import {TournamentContext, TournamentsContext, UserContext} from "../../../contexts/main";
import GamesFilter from "../../utility/GamesFilter";
import TournamentDropOutButton from "../../buttons/tournament/TournamentDropOutButton";
import SignUpIcon from "../../icons/SignUpIcon";

const TournamentCardsDisplay = () => {
    const user = useContext(UserContext)
    const [tournaments, setTournaments] = useState(useContext(TournamentsContext) as Tournament[])
    const [gamesFilter, setGamesFilter] = useState([] as string[])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [state, setState] = useState("")
    const [currentTournament, setCurrentTournament] = useState({} as Tournament)
    const [loadingGames, setLoadingGames] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const filterTournamentsByGame = (tournaments: Tournament[]) => {
        if (!gamesFilter.length) {
            return tournaments
        } else {
            return tournaments
                .filter((tournament) => {
                    if (tournament.relationships.game.data) return gamesFilter.includes(tournament.relationships.game.data.id)
                    return false
                })
        }
    }

    const deDupeTournaments = (queriedTournaments: Tournament[]) => {
        const tournamentIds = tournaments.map((tournament) => tournament.id)
        const queriedTournamentsIds = queriedTournaments.map((tournament) => tournament.id)
        const uniqueTournaments = queriedTournamentsIds.filter((tournamentId) => !tournamentIds.includes(tournamentId))
        return queriedTournaments.filter((tournament) => uniqueTournaments.includes(tournament.id))
    }

    const updateTournaments = (tournamentId: number, updatedTournament: Tournament) => {
        const foundTournament = tournaments.map((tournament) => `${tournament.id}`).includes(`${tournamentId}`)
        if (foundTournament) {
            setTournaments(tournaments.map((tournament) => {
                if (tournament.id === tournamentId) return updatedTournament
                else return tournament
            }))
        }
    }

    const loadTournaments = async () => {
        setLoadingGames(true)

        const params = new URLSearchParams()
        params.append("page", `${page + 1}`)
        params.append("per_page", `${perPage}`)
        params.append("state", `${state}`)
        params.append("include_participants", "")

        const request = await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`)

        if (request.ok) {
            const retrievedTournaments = await request.json()
            if (retrievedTournaments.length) {
                setTournaments([...tournaments, ...deDupeTournaments(retrievedTournaments)])
                setPage(page + 1)
            } else setLoadedAll(true)
        }

        setLoadingGames(false)
    }

    return (
        <>
            <Box>
                <GamesFilter gameFilterEvent={(gameId) => {
                    if (gameId.length) setGamesFilter([gameId])
                    else setGamesFilter([])
                }}/>
            </Box>

            <Wrap p={2} spacing={4} alignItems={"stretch"}>
                {
                    filterTournamentsByGame(tournaments)
                        .map((tournament) => {
                            const isComplete = tournament.attributes.state.toLowerCase() === "complete"
                            const isInProgress = tournament.attributes.state.toLowerCase() === "underway"
                            const isRegistrationOpen = !isInProgress && !isComplete
                            const isRegistered =
                                tournament.relationships.participants.links.data
                                    .some((player: Participant) => {
                                        if (user) return player.attributes.misc === user.usr
                                        else return false
                                    })

                            return (
                                <TournamentContext.Provider value={tournament}>
                                    <WrapItem key={tournament.id}>
                                        <TournamentCard key={tournament.id}>
                                            { isRegistrationOpen ?
                                                <>
                                                    <ButtonGroup ml={"auto"} mt={4} spacing='2'>
                                                        {
                                                            !isRegistered ?
                                                                <Button leftIcon={<SignUpIcon/>} colorScheme='teal' onClick={() => {
                                                                    setCurrentTournament(tournament)
                                                                    onOpen()
                                                                }}>Join Tournament</Button> :
                                                                <TournamentDropOutButton onSubmitHandler={(participantId) => {
                                                                    updateTournaments(
                                                                        tournament.id,
                                                                        Object.assign(tournament, {
                                                                            relationships: {
                                                                                participants: {
                                                                                    links: Object.assign({...tournament.relationships.participants.links}, {
                                                                                        meta: {
                                                                                            count: tournament.relationships.participants.links.meta.count - 1
                                                                                        },
                                                                                        data: tournament.relationships.participants.links.data.filter((p) => p.id !== participantId)
                                                                                    })
                                                                                }
                                                                            }
                                                                        } as unknown as Tournament)
                                                                    )
                                                              }}/>
                                                        }
                                                    </ButtonGroup>
                                                </> : <></>
                                            }
                                        </TournamentCard>
                                    </WrapItem>
                                </TournamentContext.Provider>
                            )
                        })
                }
            </Wrap>

            <Stack direction='row' p={4} spacing={4} justifyContent="center">
                {
                    !loadedAll ?
                        <Button
                            isLoading={loadingGames}
                            loadingText='Loading...'
                            colorScheme='teal'
                            variant='outline'
                            onClick={loadTournaments}
                        >
                            Load More
                        </Button>
                        :
                        <Text>There are no more tournaments.</Text>
                }
            </Stack>

            {
                !!Object.keys(currentTournament).length ?
                    <TournamentContext.Provider value={currentTournament as Tournament}>
                        <UserContextWrapper>
                            <SignUpDrawer isOpen={isOpen} onSuccess={(participant) => {
                                updateTournaments(
                                    currentTournament.id,
                                    Object.assign({...currentTournament}, {
                                        relationships: {
                                            participants: {
                                                links: Object.assign({...currentTournament.relationships.participants.links}, {
                                                    meta: {
                                                        count: currentTournament.relationships.participants.links.meta.count + 1
                                                    },
                                                    data: [...currentTournament.relationships.participants.links.data, participant]
                                                })
                                            }
                                        }
                                    } as unknown as Tournament)
                                )
                                onClose()
                            }} onClose={onClose}/>
                        </UserContextWrapper>
                    </TournamentContext.Provider> : <></>
            }
        </>
    )
}

export default TournamentCardsDisplay
