import {AbsoluteCenter, Box, Spinner, Text} from "@chakra-ui/react";

const Preloader = () => {
    return (
        <Box bg="#363636FF" sx={{height: '100vh', width: '100vw'}}>
            <AbsoluteCenter>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
                <Text>LOADING</Text>
            </AbsoluteCenter>
        </Box>
    )
}

export default Preloader
