import {Badge} from "@chakra-ui/react";

// @ts-ignore
const TournamentStateBadge = ({state}) => {
    switch (state) {
        case "complete":
            return <Badge p={1} colorScheme="red" variant="solid">Registration Closed</Badge>
        case "pending":
            return <Badge p={1} colorScheme="green" variant="solid">Registration Open</Badge>
        case "underway":
            return <Badge p={1} colorScheme="blue" variant="solid">In Progress</Badge>
        case "checking_in":
            return <Badge p={1} colorScheme="yellow" variant="solid">Checking in</Badge>
        case "awaiting_review":
            return <Badge p={1} colorScheme="orange" variant="solid">Awaiting Review</Badge>
        default:
            return (
                <></>
            )
    }
}

export default TournamentStateBadge
