import React from "react";
import {Await, Navigate, useLoaderData} from "react-router-dom";
import Preloader from "../../views/Preloader";
import {UserContext} from "../../contexts/main";

const UserContextWrapper = ({
    // @ts-ignore
    children
}) => {
    const data = useLoaderData()
    return (
        <UserContext.Provider value={(data as any).user}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextWrapper
