import {AspectRatio, Box, Heading, Image, Tag, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useContext} from "react";
import {GamesListContext, TournamentContext} from "../../contexts/main";
import TournamentInfo from "../../components/layout/tournaments/TournamentInfo";
import prettyMilliseconds from "pretty-ms";
import {formatTime} from "../../utility";
import TournamentStateBadge from "../../components/badges/TournamentStateBadge";
import {Icon} from "@iconify/react";

const TournamentView = () => {
    const tournament = useContext(TournamentContext)
    const gamesList = useContext(GamesListContext)
    const bannerImg = `/images/banners/${tournament.relationships.game.data!.id}`
    // @ts-ignore
    const duration = prettyMilliseconds(new Date(tournament.attributes.timestamps.completedAt!) - new Date(tournament.attributes.timestamps.startedAt!), {verbose: true})

    return (
        <Box w={"100%"}>
            <Box
                overflow="hidden"
                alignItems="center"
                pos="relative">
                <AspectRatio ratio={16 / 9} height={300}>
                    <Image
                        src={`${bannerImg}.png`}
                        filter='auto'
                        maxW="100%"/>
                </AspectRatio>

                <Box pos="absolute" bottom={0} py={5}>
                    <Heading
                        color='white'
                        mx={4}
                        mb={4}
                        fontSize="3em"
                        textShadow='4px 2px rgb(20,20,20)'
                        lineHeight={1}>
                        {tournament!.attributes.name}
                    </Heading>

                    <Wrap ml={4}>
                        <WrapItem>
                            <TournamentStateBadge state={tournament.attributes.state} />
                        </WrapItem>
                        <WrapItem ml={4}>
                            <Tag>
                                <Icon icon="ic:sharp-gamepad"/>
                                <Box ml={1}>{gamesList.find((game) => tournament.relationships.game.data!.id === game.game_id)!.game_name}</Box>
                            </Tag>
                        </WrapItem>
                        <WrapItem ml={4}>
                            <Tag>
                                <Icon icon="fa-solid:clock"/>
                                <Box ml={1}>{formatTime(tournament.attributes.timestamps.startsAt as unknown as string)}</Box>
                            </Tag>
                        </WrapItem>
                        <WrapItem ml={4}>
                            <Tag>
                                <Icon icon="material-symbols:group"/>
                                <Box ml={1}>{tournament.relationships.participants.links.data.length} Entrants</Box>
                            </Tag>
                        </WrapItem>
                        {
                            tournament.attributes.state.toLowerCase() === "complete" ?
                                <WrapItem ml={4}>
                                    <Tag>
                                        <Icon icon="ic:outline-hourglass-bottom"/>
                                        <Box ml={1}>{duration}</Box>
                                    </Tag>
                                </WrapItem>
                                : <></>
                        }
                    </Wrap>
                </Box>
            </Box>

            <TournamentInfo/>
        </Box>
    )
}

export default TournamentView
