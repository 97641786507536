import {Box, Center, Grid, GridItem} from "@chakra-ui/react";
import MenuPanel from "./MenuPanel";
import React from "react";

// @ts-ignore
const DashboardLayout = ({ children }) => {
    return (
        <>
            <Grid gridTemplateColumns={{base: "repeat(1, 1fr)", md: "repeat(1,250px 1fr)"}} gridAutoRows={{md: "1fr"}} flexGrow={1}>
                <GridItem bg={"gray.900"}>
                    <Box position={{md: "sticky"}}
                         top={0}
                         alignSelf={"start"}
                         zIndex={{base: 2, md: 2}}>
                        <MenuPanel/>
                    </Box>
                </GridItem>
                <GridItem overflow={"scroll"}>
                    {children}
                </GridItem>
            </Grid>
            <Box bg="gray.700" py={7} zIndex={{base: 1, md: 3}} mt={"auto"}>
                <Center color={"white"}>mtch.gg © {new Date().getFullYear()}.</Center>
            </Box>
        </>
    )
}

export default DashboardLayout
