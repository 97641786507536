// index.ts

// 1. import `extendTheme` function
import {
    createMultiStyleConfigHelpers,
    defineStyle,
    defineStyleConfig,
    extendTheme,
    type ThemeConfig
} from '@chakra-ui/react'

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}
const hMenuHelpers = createMultiStyleConfigHelpers(['hmenu', 'hitem'])
const thick = defineStyle({
    borderWidth: '5px', // change the width of the border
    borderStyle: "solid", // change the style of the border
    borderRadius: 10, // set border radius to 10
})

export const dividerTheme = defineStyleConfig({
    variants: { thick },
})

// 3. extend the index
export default extendTheme(Object.assign(config , {
    colors: {
        brand: {
            100: "#f7fafc",
            900: "#1a202c",
        },
    },
    components: {
        Divider: dividerTheme,
        HMenu: hMenuHelpers.defineMultiStyleConfig({
            baseStyle: {
                hmenu: {
                    maxW: "100vw",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                    alignItems: "stretch",
                    userSelect: "none",
                    gap: "0",
                    _hover: {cursor: "pointer"},
                },
                hitem: {
                    display: "inline-block",
                    textAlign: "center",
                    flexGrow: 1,
                    h: "100%",
                    p: 4,
                    _hover: {backgroundColor: "rgba(0,0,0,.5)"},
                    transition: ".250s all"
                }
            },
            variants: {
               active: {
                   hitem: {
                       backgroundColor: "rgba(0,0,0,.5)"
                   }
               }
            }
        })
    }
}))
