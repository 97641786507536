import React from "react";
import {Await, Navigate, useLoaderData} from "react-router-dom";
import Preloader from "../../views/Preloader";
import {UserContext} from "../../contexts/main";

const AdminWrapper = ({
    // @ts-ignore
    children
}) => {
    const data = useLoaderData()

    if (!localStorage.getItem("token") && window.location.pathname !== "/login") {
        window.location.replace(`${window.location.protocol}//${window.location.host}/login`)
    }

    return (
        <React.Suspense fallback={<Preloader/>}>
            <Await resolve={(data as any).user} errorElement={<Navigate to="/login"/>}>
                {(user) => {
                    const userData = user.msc
                    if (userData.isAdmin) {
                        return (
                            <UserContext.Provider value={user}>
                                {children}
                            </UserContext.Provider>
                        )
                    } else return <Navigate to="/"/>
                }}
            </Await>
        </React.Suspense>
    )
}

export default AdminWrapper
