import {Box, Button, Center, TabPanel, TabPanels, Tabs, Text, useDisclosure} from "@chakra-ui/react";
import {AppConfigContext, MatchContext, MatchesContext, TournamentContext, UserContext} from "../../../contexts/main";
import React, {useContext, useState} from "react";
import PlayersList from "./PlayersList";
import TournamentResults from "./Results";
import MatchDisplay from "./MatchDisplay";
import {Icon} from "@iconify/react";
import TournamentRegistration from "./Registration";
import {useNavigate} from "react-router-dom";
import Bracket from "./Bracket";
import ReportScoreModal from "../../overlays/ReportScoreModal";
import {addGrandsResetRound, checkBracketReset} from "../../../utility/challonge/tournamentUtils";
import ChatBox from "../chatbox/ChatBox";
import ChatRoomsContextWrapper from "../../utility/ChatRoomsContextWrapper";
import HMenu from "./HMenu";
import HMenuItem from "./HMenuItem";

const TournamentInfo = () => {
    const [tournament, setTournament] = useState(useContext(TournamentContext))
    const [tabIndex, setTabIndex] = useState(0)
    const [matches, setMatches] = useState(
        tournament.attributes.state === "complete" && !checkBracketReset(tournament.relationships.matches.links.data) ?
            tournament.relationships.matches.links.data :
            addGrandsResetRound(tournament.relationships.matches.links.data)
    )
    const appConfig = useContext(AppConfigContext)
    const user = useContext(UserContext)
    const isLoggedIn = user !== undefined
    const isFinalized = tournament.attributes.state === "complete"
    const playerCount = tournament.relationships.participants.links.meta.count
    const navigate = useNavigate()
    const [currentMatch, setCurrentMatch] = useState(matches[0])
    const { isOpen, onOpen, onClose } = useDisclosure()

    const addParticipant = (participant: Participant) => {
        setTournament(Object.assign(tournament, {
            relationships: {
                participants: {
                    links: Object.assign({...tournament.relationships.participants.links}, {
                        meta: {
                            count: tournament.relationships.participants.links.meta.count + 1
                        },
                        data: [...tournament.relationships.participants.links.data, participant]
                    })
                }
            }
        } as unknown as Tournament))
    }

    const removeParticipant = (participantId: string) => {
        setTournament(Object.assign(tournament, {
            relationships: {
                participants: {
                    links: Object.assign({...tournament.relationships.participants.links}, {
                        meta: {
                            count: tournament.relationships.participants.links.meta.count - 1
                        },
                        data: tournament.relationships.participants.links.data.filter((p) => p.id !== participantId)
                    })
                }
            }
        } as unknown as Tournament))
    }

    const reloadMatches = async () => {
        const request = await fetch(`${appConfig.S1_API}/tournaments/${tournament.id}/matches`, {
            method: "GET"
        })

        if (request.ok) {
            const updatedMatches: Matches = await request.json()
            if (updatedMatches) setMatches(
                tournament.attributes.state === "complete" && !checkBracketReset(updatedMatches) ?
                    updatedMatches : addGrandsResetRound(updatedMatches)
            )
        }
    }

    return (
       <Box>
           <TournamentContext.Provider value={tournament}>
               <HMenu>
                   <HMenuItem variant={tabIndex === 0 ? "active" : ""} callback={() => {setTabIndex(0)}}>
                       <Box display={"inline-block"}>
                           <Icon fontSize="1.5em" icon="mdi:bracket"/>
                       </Box>
                       <Text>Bracket</Text>
                   </HMenuItem>
                   <HMenuItem variant={tabIndex === 1 ? "active" : ""} callback={() => {setTabIndex(1)}}>
                       <Box display={"inline-block"}>
                           <Icon fontSize="1.5em" icon="wpf:group"/>
                       </Box>
                       <Text>Players</Text>
                   </HMenuItem>
                   <HMenuItem variant={tabIndex === 2 ? "active" : ""} callback={() => {setTabIndex(2)}}>
                       <Box display={"inline-block"}>
                           <Icon fontSize="1.5em" icon="game-icons:podium"/>
                       </Box>
                       <Text>Results</Text>
                   </HMenuItem>
                   <HMenuItem variant={tabIndex === 3 ? "active" : ""} callback={() => {setTabIndex(3)}}>
                       <Box display={"inline-block"}>
                           <Icon fontSize="1.5em" icon="medical-icon:registration"/>
                       </Box>
                       <Text>Register</Text>
                   </HMenuItem>
                   <HMenuItem variant={tabIndex === 4 ? "active" : ""} callback={() => {setTabIndex(4)}}>
                       <Box display={"inline-block"}>
                           <Icon fontSize="1.5em" icon="mdi:chat"/>
                       </Box>
                       <Text>Chat</Text>
                   </HMenuItem>
               </HMenu>
               <Tabs index={tabIndex} variant={"unstyled"}>
                   <TabPanels>
                       <TabPanel>
                           {
                               playerCount ?
                                   <Box>
                                       <MatchesContext.Provider value={matches}>
                                           { isLoggedIn &&
                                               (() => {
                                                   const isRegistered = tournament.relationships.participants.links.data
                                                       .some((player: Participant) => player.attributes.misc === user.usr)
                                                   return isRegistered ? <MatchDisplay/> : <></>
                                               })()
                                           }

                                           <Bracket onMatchClick={(match) => {
                                               setCurrentMatch(match)
                                               onOpen()
                                           }}/>
                                       </MatchesContext.Provider>

                                       <MatchContext.Provider value={currentMatch}>
                                           {
                                               currentMatch &&
                                             <ReportScoreModal
                                               isOpen={isOpen}
                                               onOpen={onOpen}
                                               onClose={onClose}
                                               onSuccess={async () => {
                                                   await reloadMatches()
                                               }}
                                             />
                                           }
                                       </MatchContext.Provider>
                                   </Box>
                                   : <Center>Bracket preview unavailable.</Center>
                           }
                       </TabPanel>
                       <TabPanel>
                           <PlayersList/>
                       </TabPanel>
                       <TabPanel>
                           <>
                               {
                                   isFinalized
                                       ? <TournamentResults/>
                                       : <Center>Results are not available yet.</Center>
                               }
                           </>
                       </TabPanel>
                       <TabPanel>
                           {
                               isLoggedIn ?
                                   <TournamentRegistration
                                       onRegister={(participant) => addParticipant(participant)}
                                       onDrop={(participantId) => removeParticipant(participantId)}/> :
                                   <Center>
                                       <Button onClick={() => navigate("/login")} colorScheme="green">Login to register</Button>
                                   </Center>
                           }
                       </TabPanel>
                       <TabPanel>
                           <ChatRoomsContextWrapper><ChatBox useInput={isLoggedIn}/></ChatRoomsContextWrapper>
                       </TabPanel>
                   </TabPanels>
               </Tabs>
           </TournamentContext.Provider>
       </Box>
    )
}

export default TournamentInfo
