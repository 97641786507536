import React from "react";
import TournamentsContextWrapper from "../../components/utility/TournamentsContextWrapper";
import TournamentCardsDisplay from "../../components/layout/tournaments/TournamentCardsDisplay";
import UserContextWrapper from "../../components/utility/UserContextWrapper";
import DashboardLayout from "../../components/layout/DashboardLayout";

const Tournaments = () => {
    return (
        <TournamentsContextWrapper>
            <UserContextWrapper>
                <DashboardLayout>
                    <TournamentCardsDisplay/>
                </DashboardLayout>
            </UserContextWrapper>
        </TournamentsContextWrapper>
    )
}

export default Tournaments
