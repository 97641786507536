import {Box, chakra, useMultiStyleConfig} from "@chakra-ui/react";
import React from "react";

const HMenuItem = (props: {
    size?: any,
    variant?: any,
    callback: () => void | {},
    children: any,
}) => {
    const { size, variant } = props
    const styles = useMultiStyleConfig('HMenu', { size, variant })

    return (
        <Box
            onClick={() => props.callback && props.callback()}
            __css={styles.hitem}>
            {props.children}
        </Box>
    )
}

export default chakra(HMenuItem)
