import {useLoaderData} from "react-router-dom";
import {GamesListContext, TournamentContext} from "../../contexts/main";
import DashboardLayout from "../../components/layout/DashboardLayout";
import AdminWrapper from "../../components/utility/AdminWrapper";
import {Container} from "@chakra-ui/react";
import TournamentsEditForm from "../../components/forms/tournaments/TournamentsEditForm";

const HomePage = () => {
    const data = useLoaderData() as any
    const {tournament, gamesList} = data
    return (
        <AdminWrapper>
            <DashboardLayout>
                <Container p={4}>
                    <TournamentContext.Provider value={tournament}>
                        <GamesListContext.Provider value={gamesList}>
                            <TournamentsEditForm/>
                        </GamesListContext.Provider>
                    </TournamentContext.Provider>
                </Container>
            </DashboardLayout>
        </AdminWrapper>
    )
}

export default HomePage
