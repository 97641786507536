import React from 'react';
import './App.css';
import {Box, ChakraProvider} from "@chakra-ui/react";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import APP_ROUTES from "./routes";
import {APP_CONFIG, AppConfigContext} from "./contexts/main";
import theme from "./theme"
import Preloader from "./views/Preloader";

const router = createBrowserRouter([
    ...APP_ROUTES
], {
    future: {
        v7_normalizeFormMethod: true,
    }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppConfigContext.Provider value={APP_CONFIG}>
          <Box className="App" height="100vh" minH={"100vh"} maxW={"100vw"} overflow="scroll">
              <RouterProvider router={router} fallbackElement={<Preloader/>} />
          </Box>
      </AppConfigContext.Provider>
    </ChakraProvider>
  );
}

export default App;
