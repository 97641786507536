import BracketSet from "./BracketSet";
import BracketEntrant from "./BracketEntrant";
import {sortSeeds} from "../../index";

interface BracketMetaData {
    date?: string
    gameName?: string
}

class BracketEvent {
    name?: string
    numberOfEntrants = 3
    root
    winnersRoot?: BracketSet
    losersRoot?: BracketSet
    entrants?: Array<BracketEntrant>
    layout: ChallongeTournamentTypes
    other: BracketMetaData = {
        date: new Date().toDateString(),
        gameName: "DNF Duel"
    }

    constructor(props: {
        name?: string
        entrants: BracketEntrant[]
        layout: ChallongeTournamentTypes
        metaData?: BracketMetaData
    }) {
        const {
            name,
            entrants,
            layout,
            metaData
        } = props
        this.name = name || "Generic OBF Tournament"
        this.numberOfEntrants = entrants.length
        this.layout = layout
        this.root = this.createBracket()
        this.winnersRoot = this.root
        this.entrants = this.assignEntrants(entrants)
        this.addMetaData(metaData)
        if (layout === 'Double Elimination') {
            this.root = this.attachLosersBracket(this.root!)
        }
    }

    assignEntrants (entrants: BracketEntrant[]) {
        const round1Byes = entrants.slice(0, this.findHighestPowerOf2(entrants.length) - entrants.length)
        const pairedRound1Entrants =
            entrants
                .slice(round1Byes.length)
                .map((entrant, index, array) => (index < this.getSetsByRound(1).length) && [entrant, array.pop() as BracketEntrant].sort(sortSeeds))
                .filter((entrants) => entrants)
        const numberOfRound1Qualifiers = this.getSetsByRound(1).filter((set) => !set.isOnlyChild()).reverse()
        const round2Entrants =
            [
                round1Byes.slice(0, numberOfRound1Qualifiers.length)
                    .map((entrant) => [entrant]),
                round1Byes.slice(numberOfRound1Qualifiers.length)
                    .map((entrant, index, array) => (index < this.getSetsByRound(2).length) && [entrant, array.pop() as BracketEntrant].sort(sortSeeds))
                    .filter((set) => set) as unknown as BracketEntrant[]
            ].flat() as unknown as BracketEntrant[]

        if (this.isPowerOf2(this.numberOfEntrants)) {
            this.getSetsByRound(1)
                .forEach((set: BracketSet) => {
                    const players = pairedRound1Entrants.find((entrants) => entrants && entrants[0].entrantID === set.setId) || pairedRound1Entrants.pop()
                    players && set.setEntrant(players[0])
                    players && set.setEntrant(players[1])
                })
        } else {
            const shuffledRound2Sets =
                this.shuffleItems(
                    [
                        this.shuffleItems(this.getSetsByRound(2).slice(0, this.getSetsByRound(2).length / 2)),
                        this.shuffleItems(this.getSetsByRound(2).slice(this.getSetsByRound(2).length / 2))
                    ].flat()
                )

            pairedRound1Entrants.reverse()

            shuffledRound2Sets
                .forEach((set: BracketSet) => {
                    const players = pairedRound1Entrants.shift()

                    if (!players) return

                    if (set.rightSet) {
                        if (!set.rightSet.leftEntrant || !set.rightSet.rightEntrant) set.rightSet.setEntrant(players[0])
                        if (!set.rightSet.rightEntrant) set.rightSet.setEntrant(players[1])
                        players.splice(0, players.length, ...pairedRound1Entrants.shift() as unknown as BracketEntrant[])
                    }

                    if (set.leftSet) {
                        if (!players.length) pairedRound1Entrants.length && players.push(...pairedRound1Entrants.shift() as unknown as BracketEntrant[])
                        if (!set.leftSet.leftEntrant || !set.leftSet.rightEntrant) set.leftSet.setEntrant(players[0])
                        else if (set.leftSet.leftEntrant && !set.leftSet.rightEntrant) set.leftSet.setEntrant(players[0])
                        if (!set.leftSet.rightEntrant) set.leftSet.setEntrant(players[1])
                    }
                })

            shuffledRound2Sets
                .forEach((set: BracketSet) => {
                    if (set.leftSet && set.rightSet) return
                    const entrants = round2Entrants.shift() as unknown as BracketEntrant[]
                    if (!entrants) return
                    if (!set.leftEntrant) set.setEntrant(entrants[0])
                    else if (set.leftEntrant && !set.rightEntrant) set.setEntrant(entrants[0])
                    if (!set.rightEntrant) set.setEntrant(entrants[1])
                })
        }

        return entrants
    }

    createBracket(size: number = this.numberOfEntrants) {
        if (size < 2) return

        let currentRound = 1
        let previousRoundSets = [] as unknown as BracketSet[]
        let root

        const numberOfRounds = this.calculateRounds(size)
        const byesFromRound1 = this.findHighestPowerOf2(size) - size
        const calculateNumberOfSets = (round: number) => {
            switch (round) {
                case 1:
                    return (size - byesFromRound1) / 2
                case 2:
                    return (byesFromRound1 + previousRoundSets.length) / 2
                default:
                    return previousRoundSets.length / 2
            }
        }

        const winnersSets = []
        while (currentRound <= numberOfRounds) {
            const lastIndex = previousRoundSets.length ? previousRoundSets.slice(-1)[0].setId : 0
            const currentRoundSets = []

            for (let index = 0; index < calculateNumberOfSets(currentRound); index++) {
                currentRoundSets.push(new BracketSet({
                    setId: index + 1 + (lastIndex || 0),
                    round: currentRound
                }))
            }

            switch (currentRound) {
                case 1:
                    break
                case 2:
                    const sets = new Array(currentRoundSets.length).fill(undefined)
                    sets.splice(0, previousRoundSets.length, ...previousRoundSets.slice(0))
                    const shuffledSets = this.shuffleItems(currentRoundSets.slice(0))

                    if (shuffledSets.length >= 8) {
                        const topSets = this.shuffleItems(currentRoundSets.slice(0).slice(0, currentRoundSets.length / 2))
                        const bottomSets = this.shuffleItems(currentRoundSets.slice(0).slice(currentRoundSets.length / 2))
                        const reShuffledSets = this.shuffleItems([topSets, bottomSets].flat())

                        while (sets.length) {
                            reShuffledSets.forEach((set) => set.addSet(sets.shift()))
                            sets.length && reShuffledSets.reverse().forEach((set) => set.addSet(sets.shift()))
                        }
                    } else {
                        while (sets.length) {
                            this.shuffleItems(currentRoundSets.slice(0))
                                .forEach((set) => set.addSet(sets.shift()))
                            this.shuffleItems(currentRoundSets.slice(0)).reverse()
                                .forEach((set) => set.addSet(sets.shift()))
                        }
                    }
                    break
                default:
                    currentRoundSets.forEach((set) => {
                        set.addSet(previousRoundSets.shift())
                        set.addSet(previousRoundSets.shift())
                    })
                    break
            }

            previousRoundSets = currentRoundSets
            winnersSets.push(...currentRoundSets)
            currentRound++
        }

        root = winnersSets && winnersSets.slice(-1)![0]

        return root
    }

    shuffleItems (items: any[]) {
        if (items.length < 3) return items
        const combined = [] as unknown as Array<any|undefined>
        const topHalf = items.slice(0, items.length / 2)
        const bottomHalf = items.slice(items.length / 2)

        let i = 2
        while (topHalf.length || bottomHalf.length) {
            if (i !== 4 && i !== 1) combined.push(topHalf.shift())
            else combined.push(bottomHalf.shift())
            i = i - 1 === 0 ? 4 : i - 1
        }

        return combined
    }

    weaveItems (items: any[]) {
        if (items.length === 1) return [items, [undefined]]
        if (items.length === 2) return [[items.shift()], [items.shift()]]
        const topHalf = [items.shift()] as unknown as Array<any|undefined>
        const bottomHalf = [items.shift()] as unknown as Array<any|undefined>

        let i = 4
        while (items.length) {
            if (i !== 4 && i !== 1) topHalf.splice(1, 0, items.shift())
            else bottomHalf.splice(1, 0, items.shift())
            i = i - 1 === 0 ? 4 : i - 1
        }

        return [topHalf, bottomHalf].flat()
    }

    attachLosersBracket (winnersFinals: BracketSet) {
        if (!winnersFinals) return winnersFinals

        const grandFinals = new BracketSet({
            setId: (winnersFinals.setId! * 2),
            type: "winners",
            round: winnersFinals.round!
        })

        const grandFinalsReset = new BracketSet({
            setId: (winnersFinals.setId! * 2) + 1,
            type: "winners",
            round: winnersFinals.round! + 1
        })

        this.losersRoot = this.createLosersBracket(winnersFinals)
        this.winnersRoot = grandFinals

        grandFinals.addLeftSet(winnersFinals)
        grandFinalsReset.addSet(grandFinals)

        if (this.numberOfEntrants > 2) {
            grandFinals.addRightSet(this.losersRoot)
            grandFinals.setLosersSet(grandFinalsReset)
            winnersFinals.setLosersSet(this.losersRoot)
        }

        return grandFinalsReset
    }

    linkLosersSets (losersBracket: BracketSet[]) {
        const numberOfRounds = losersBracket[losersBracket.length - 1].round!
        let firstTimeLoserSets = this.getAllWinnersSets()
        let round = 1

        while (round <= numberOfRounds) {
            const currentRoundSets = losersBracket.filter((node) => node.round! === round)
            const nextRoundSets = losersBracket.filter((node) => node.round! === round + 1)

            switch (round) {
                case 1:
                    const copy = currentRoundSets.slice(0)
                    while (copy.length) {
                        const round1WinnersSets = firstTimeLoserSets.filter((set: BracketSet) => set.isLeftChild() && set.round === 1 )
                        const round2WinnersSets = firstTimeLoserSets.filter((set: BracketSet) => set.round === 2 )

                        round1WinnersSets
                            .forEach((set, index) => {
                                if (set.getSibling()) {
                                    const loser = copy.shift()
                                    const position = round2WinnersSets.length - index - 1
                                    nextRoundSets[index].addSet(loser)
                                    set.setLosersSet(loser)
                                    set.getSibling()!.setLosersSet(loser)
                                    loser && loser.setParentSet(nextRoundSets[index])
                                    loser && round2WinnersSets.slice(position, position + 1)[0].setLosersSet(loser.parentSet!)
                                } else {
                                    const position = round2WinnersSets.length - index - 1
                                    round2WinnersSets.slice(position, position + 1)[0]!.setLosersSet(nextRoundSets[index])
                                    set.setLosersSet(nextRoundSets[index])
                                }
                            })
                    }

                    const winnersRound1 = this.getSetsByRound(1, {type: "winners"})
                    const winnersRound2 = this.getSetsByRound(2, {type: "winners"}).reverse()

                    // if (currentRoundSets.some((set) => set.getSibling())) {
                    //     nextRoundSets
                    //         .forEach((set, index) => {
                    //             winnersRound2[index].setLosersSet(set)
                    //             winnersRound1[index].setLosersSet(set)
                    //         })
                    // } else {
                    //     nextRoundSets
                    //         .forEach((set, index) => {
                    //             winnersRound2[index].setLosersSet(set)
                    //             winnersRound1[index].setLosersSet(set)
                    //         })
                    // }

                    firstTimeLoserSets = firstTimeLoserSets.filter((firstTimeLoserSet) => {
                        return !currentRoundSets.find((set) => {
                            if (set.leftWinnerSet?.setId === firstTimeLoserSet.setId) return true
                            else return set.rightWinnerSet?.setId === firstTimeLoserSet.setId;
                        })
                    })

                    break
                default:
                    if (nextRoundSets.length) {
                        if (currentRoundSets.length !== nextRoundSets.length) {
                            currentRoundSets
                                .forEach((set) => {
                                    const parent = nextRoundSets.find((parent) => !parent.leftSet || !parent.rightSet)
                                    parent && parent.addSet(set)
                                })
                        } else {
                            currentRoundSets
                                .forEach((set, index) => {
                                    const parent = nextRoundSets[index]
                                    parent && parent.addSet(set)
                                })
                        }
                    }

                    currentRoundSets.forEach((set) => {
                        if (!set.leftSet) {
                            const firstTimeLoserSet = firstTimeLoserSets.shift()!
                            firstTimeLoserSet && firstTimeLoserSet.setLosersSet(set)
                        }

                        if (!set.rightSet) {
                            const firstTimeLoserSet = firstTimeLoserSets.shift()!
                            if (set.getSibling()) firstTimeLoserSet && firstTimeLoserSet.setLosersSet(set.getSibling())
                            else firstTimeLoserSet && firstTimeLoserSet.setLosersSet(set)
                        }
                    })
                    break
            }

            round++
        }

        return losersBracket.slice(-1)[0]
    }

    createLosersBracket (winnersFinals: BracketSet) {
        if (this.numberOfEntrants <= 2) return winnersFinals

        const losersSets = [] as unknown as BracketSet[]
        const firstTimeLosers = this.getSetsByRound(1).length + this.getSetsByRound(2).length
        const firstTimeLosersByes = this.findHighestPowerOf2(firstTimeLosers) - firstTimeLosers

        let setId = winnersFinals.setId! + 1
        let setIdIncrement = 0
        let numberOfSets = 0
        let surplus = 0
        let round = 1
        let winnersRound = round

        while (setIdIncrement < this.numberOfEntrants - 2) {
            const winnersRoundSets = this.getSetsByRound(winnersRound)
            const previousRoundSets = losersSets.filter((set) => set.round! === round - 1)

            if (!winnersRoundSets.length) {
                winnersRoundSets.push(...this.getSetsByRound(winnersRound - 1))
            }

            switch (round) {
                case 1:
                    numberOfSets = (firstTimeLosers - firstTimeLosersByes) / 2
                    surplus = winnersRoundSets.length - (numberOfSets * 2)

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    break
                case 2:
                    numberOfSets = (firstTimeLosersByes + previousRoundSets.length) / 2

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    surplus = 0

                    break
                default:
                    if (this.isPowerOf2(previousRoundSets.length + winnersRoundSets.length)) {
                        numberOfSets = (previousRoundSets.length + winnersRoundSets.length) / 2
                    } else {
                        if (this.isPowerOf2(previousRoundSets.length + surplus)) {
                            numberOfSets = (previousRoundSets.length + surplus) / 2
                            surplus = winnersRoundSets.length
                        } else numberOfSets = previousRoundSets.length / 2
                    }

                    for (let i = 0; i < numberOfSets; i++) {
                        losersSets[setIdIncrement] = new BracketSet({
                            setId: setId + setIdIncrement,
                            type: "losers",
                            round: round
                        })
                        setIdIncrement++
                    }

                    break
            }

            winnersRound++
            round++
        }

        const loserFinals = losersSets.slice(-1)[0]

        if (loserFinals.round === losersSets.slice(-2)[0].round) loserFinals.round = loserFinals.round! + 1

        return this.linkLosersSets(losersSets.flat())
    }

    getSetById(id: number, set = this.root!): BracketSet | undefined {
        let found = undefined
        if (set.setId === id) return set
        if (set.leftSet) found = this.getSetById(id, set.leftSet)
        if (set.rightSet && !found) found = this.getSetById(id, set.rightSet)
        return found
    }

    getAllWinnersSets () {
        let round = 1
        const rounds = this.calculateRounds()
        const result = []
        while (round <= rounds) {
            result.push(this.getSetsByRound(round, {type: "winners"}))
            round++
        }
        return result.flat()
    }

    getAllLosersSets () {
        let round = 1
        const rounds = this.calculateRounds()
        const result = []
        while (round <= rounds) {
            result.push(this.getSetsByRound(round, {type: "losers"}))
            round++
        }
        return result.flat()
    }

    getSetsByRound(round: number, filters?: {
        set?: BracketSet,
        type?: "winners" | "losers"
    }): BracketSet[] {
        const {set = this.root!, type = false} = filters ? filters : {}
        const sets = [] as BracketSet[]
        if (set.round === round) sets.push(set)
        if (set.leftSet) sets.push(...this.getSetsByRound(round, {set: set.leftSet}))
        if (set.rightSet) sets.push(...this.getSetsByRound(round, {set: set.rightSet}))
        if (type) return sets.filter((game) => game.type === type)
        return sets
    }

    calculateRounds(size: number = this.numberOfEntrants) {
        let rounds = 1
        while (Math.pow(2,rounds) < size) rounds++
        return rounds
    }

    findHighestPowerOf2(threshold: number = this.numberOfEntrants) {
        if (threshold < 2) return threshold
        if (this.isPowerOf2(threshold)) return threshold
        let i = 1
        while (threshold > Math.pow(2, i) && threshold !== Math.pow(2, i)) i++
        return Math.pow(2, i)
    }

    isPowerOf2(x: number) { return (Math.log2(x) % 1 === 0) }
    addMetaData(data: any) { this.other = Object.assign(this.other, data) }
}

export default BracketEvent
