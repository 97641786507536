import {Avatar, Box, Center, HStack} from "@chakra-ui/react";
import React, {useContext} from "react";
import {TournamentContext} from "../../../contexts/main";

const PlayersList = () => {
    const tournament = useContext(TournamentContext)
    const playerCount = tournament.relationships.participants.links.meta.count

    return (
        <Box>
            {
                tournament.relationships.participants.links.data.map((participant, index) => {
                    return (
                        <HStack key={index}>
                            <Avatar name={`${participant.attributes.name}`} />
                            <Box>{participant.attributes.name} (seed: {participant.attributes.seed})</Box>
                        </HStack>
                    )
                })
            }
            <Center>
                <small>{playerCount} participants</small>
            </Center>
        </Box>
    )
}

export default PlayersList
