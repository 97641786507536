import {DeleteIcon, EditIcon, HamburgerIcon, RepeatIcon} from "@chakra-ui/icons";
import {
    IconButton,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {Icon} from "@iconify/react";
import React, {useContext, useState} from "react";
import {AppConfigContext, TournamentContext, TournamentsContext} from "../../../contexts/main";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";
import DeleteTournamentModal from "../../overlays/DeleteTournamentModal";
import {getStatusBadge} from "../../../utility";

const TournamentsList = () => {
    const tournaments = useContext(TournamentsContext)
    const appConfig = useContext(AppConfigContext)
    const [tournamentList, setTournamentList] = useState(tournaments)
    const [currentTournament, setCurrentTournament] = useState(tournaments[0])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()

    const deleteTournament = async () => {
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${currentTournament.id}/delete`, {
            method: "DELETE",
            headers,
            body: JSON.stringify(currentTournament)
        }).then(async (response) => {
            if (response.ok) setTournamentList([...tournamentList.filter((t) => t.id !== currentTournament.id)])
        }).finally(() => onClose())
    }

    const startTournament = async (tournament: Tournament) => {
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/start`, {
                method: "PUT",
                headers,
            }).then(async (response) => {
                if (response.ok) {
                    const startedTournament = await response.json()
                    setTournamentList(
                        tournamentList.map((t) => {
                            if (t.id === tournament.id) return startedTournament
                            else return t
                        })
                    )
                    resolve(tournamentList)
                } else {
                    reject(response.statusText)
                }
            })
        })
    }

    const finalizeTournament = async (tournament: Tournament) => {
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/finalize`, {
                method: "PUT",
                headers,
            }).then(async (response) => {
                if (response.ok) {
                    const finalizedTournament = await response.json()
                    setTournamentList(
                        tournamentList.map((t) => {
                            if (t.id === tournament.id) return finalizedTournament
                            else return t
                        })
                    )
                    resolve(tournamentList)
                } else {
                    reject(response.statusText)
                }
            })
        })
    }

    const resetTournament = async (tournament: Tournament) => {
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/reset`, {
                method: "PUT",
                headers,
            }).then(async (response) => {
                if (response.ok) {
                    const resetTournament = await response.json()
                    setTournamentList(
                        tournamentList.map((t) => {
                            if (t.id === tournament.id) return resetTournament
                            else return t
                        })
                    )
                    resolve(tournamentList)
                } else {
                    reject(response.statusText)
                }
            })
        })
    }

    return (
        <TournamentContext.Provider value={currentTournament}>
            <TableContainer>
                <Table>
                    <TableCaption>{tournamentList.length} Tournaments.</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Status</Th>
                            <Th>Tournament</Th>
                            <Th>Start Time</Th>
                            <Th isNumeric># of Players</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            tournamentList.map((tournament) => {
                                const playerCount = tournament.relationships.participants.links.meta.count
                                return (
                                    <TournamentContext.Provider value={tournament}>
                                        <Tr>
                                            <Td>{getStatusBadge(tournament.attributes.state)}</Td>
                                            <Td>
                                                <Link href={`${appConfig.BASE_URL}/tournaments/${tournament.attributes.url}`}>
                                                    {tournament.attributes.name}
                                                </Link>
                                            </Td>
                                            <Td>{format(new Date(tournament.attributes.timestamps.startsAt), "MMM do, yyyy @ hh:mm aa")}</Td>
                                            <Td isNumeric>{playerCount}</Td>
                                            <Td>
                                                <Menu>
                                                    <MenuButton
                                                        as={IconButton}
                                                        aria-label='Options'
                                                        icon={<HamburgerIcon />}
                                                        variant='outline'
                                                    />
                                                    <MenuList>
                                                        <MenuItem onClick={() => {
                                                            toast.promise(startTournament(tournament), {
                                                                success: { title: `${tournament.attributes.name} has started!`, description: ''},
                                                                error: { title: `There was an error starting ${tournament.attributes.name}`, description: ''},
                                                                loading: { title: `Starting ${tournament.attributes.name}...`, description: ''},
                                                            })
                                                        }} icon={<Icon icon="gravity-ui:play-fill"/>}>
                                                            Start
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            toast.promise(finalizeTournament(tournament), {
                                                                success: { title: `${tournament.attributes.name} has finished!`, description: ''},
                                                                error: { title: `There was an error finalizing ${tournament.attributes.name}`, description: ''},
                                                                loading: { title: `Finalizing ${tournament.attributes.name}...`, description: ''},
                                                            })
                                                        }} icon={<Icon icon="gis:flag-finish-b-o"/>}>
                                                            Finalize
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            toast.promise(resetTournament(tournament), {
                                                                success: { title: `${tournament.attributes.name} has been reset!`, description: ''},
                                                                error: { title: `There was an error resetting ${tournament.attributes.name}`, description: ``},
                                                                loading: { title: `Resetting ${tournament.attributes.name}...`, description: ''},
                                                            })
                                                        }} icon={<RepeatIcon />}>
                                                            Reset
                                                        </MenuItem>
                                                        <MenuItem onClick={() => navigate(`/tournaments/edit/${tournament.id}`)} icon={<EditIcon />}>
                                                            Edit
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            setCurrentTournament(tournament)
                                                            onOpen()
                                                        }} icon={<DeleteIcon />}>
                                                            Delete
                                                        </MenuItem>
                                                        <MenuItem as={"a"} href={tournament.attributes.fullChallongeUrl} icon={<Icon icon="gg:external"/>}>
                                                            View on Challonge
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    </TournamentContext.Provider>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <DeleteTournamentModal isOpen={isOpen} onSubmit={deleteTournament} onClose={onClose}/>
        </TournamentContext.Provider>
    )
}

export default TournamentsList
