import {Button} from "@chakra-ui/react";
import {useContext} from "react";
import {AppConfigContext} from "../../../contexts/main";
import ChallongeIcon from "../../icons/ChallongeIcon";

const ChallongeLogin = () => {
    const appConfig = useContext(AppConfigContext);
    const redirect = () => {
        const client_id = `&client_id=${appConfig.CHALLONGE_CLIENT_ID}`
        const scopes = [
            `me`,
            `tournaments:write`,
            `tournaments:read`,
            `participants:write`,
            `participants:read`,
            `attachments:read`,
            `attachments:write`,
        ]
        const redirect_uri = `&redirect_uri=${process.env.REACT_APP_S1_API_ENDPOINT}/challonge/oauth`
        window.location.href = `https://api.challonge.com/oauth/authorize?response_type=code${client_id}&scope=${scopes.join(" ")}${redirect_uri}`
    }

    return (
        <>
            <Button
                leftIcon={<ChallongeIcon/>}
                onClick={redirect}
                colorScheme='orange'>
                Challonge
            </Button>
        </>
    )
}

export default ChallongeLogin
