import React from "react";
import {useLoaderData} from "react-router-dom";
import {GamesListContext} from "../../contexts/main";

const GamesListContextWrapper = (props: {
    children: any
}) => {
    const data = useLoaderData()
    const {children} = props
    return (
        <GamesListContext.Provider value={(data as any).gamesList}>
            {children}
        </GamesListContext.Provider>
    )
}

export default GamesListContextWrapper
