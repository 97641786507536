import Navigation from "./Navigation";
import UserContextWrapper from "../utility/UserContextWrapper";
import LoggedInUser from "../utility/LoggedInUser";

// @ts-ignore
const MenuPanel = () => {
    return (
        <UserContextWrapper>
            <LoggedInUser/>
            <Navigation/>
        </UserContextWrapper>
    )
}

export default MenuPanel
