import Preloader from "../../views/Preloader";
import {Await, useLoaderData} from "react-router-dom";
import {Text} from "@chakra-ui/react";
import {TournamentsContext} from "../../contexts/main";
import React from "react";

const TournamentsContextWrapper = (props: {
    children: any
}) => {
    const data = useLoaderData()
    const {children} = props
    return (
        <React.Suspense fallback={<Preloader/>}>
            <Await
                // @ts-ignore
                resolve={(data as any).tournaments as Promise}
                errorElement={<Text>No Tournaments Found.</Text>}>
                {
                    (tournaments) => {
                        return (
                            <TournamentsContext.Provider value={tournaments}>
                                {children}
                            </TournamentsContext.Provider>
                        )
                    }
                }
            </Await>
        </React.Suspense>
    )
}

export default TournamentsContextWrapper
