import { createContext } from 'react';

export const APP_CONFIG = {
    DISCORD_CLIENT_ID: process.env.REACT_APP_DISCORD_CLIENT_ID,
    CHALLONGE_CLIENT_ID: process.env.REACT_APP_CHALLONGE_CLIENT_ID,
    START_GG_CLIENT_ID: process.env.REACT_APP_STARTGG_OAUTH_CLIENT_ID,
    TWITTER_CLIENT_ID: process.env.REACT_APP_TWITTER_OAUTH_CLIENT_ID,
    S1_API: process.env.REACT_APP_S1_API_ENDPOINT,
    S1_WS: process.env.REACT_APP_S1_WS_ENDPOINT,
    BASE_URL: `${window.location.protocol}//${window.location.host}`
}
export const AppConfigContext = createContext(APP_CONFIG as unknown as {
    DISCORD_CLIENT_ID: string | undefined,
    CHALLONGE_CLIENT_ID: string | undefined,
    START_GG_CLIENT_ID: string | undefined,
    TWITTER_CLIENT_ID: string | undefined,
    S1_API: string | undefined,
    S1_WS: string | undefined,
    BASE_URL: string,
})

export const UserContext = createContext(undefined as unknown as AuthenticatedUser)
export const TournamentContext = createContext(undefined as unknown as Tournament)
export const TournamentsContext = createContext(undefined as unknown as Tournament[])
export const MatchContext = createContext(undefined as unknown as Match)
export const MatchesContext = createContext(undefined as unknown as Matches)
export const GamesListContext = createContext(undefined as unknown as GamesList)
export const ChatRoomsContext = createContext(undefined as unknown as { name: string, id: string}[])
