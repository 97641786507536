import {AbsoluteCenter, Box, Center, Divider, Grid, GridItem, Heading, SimpleGrid} from "@chakra-ui/react";
import React, {useContext} from "react";
import {TournamentContext} from "../../../contexts/main";
import {Icon} from "@iconify/react";
import {formatOrdinals} from "../../../utility";

const TournamentResults = () => {
    const tournament = useContext(TournamentContext)
    const isFinalized = tournament.attributes.state === "complete"
    const players = tournament.relationships.participants.links.data as Participant[]
    const top8 =
        players
            .filter((participant) => participant.attributes.finalRank! < 9)
            .sort((a, b) => a.attributes.finalRank! < b.attributes.finalRank! ? -1 : 1)
    const theRest =
        players
            .filter((participant) => participant.attributes.finalRank! >= 9)
            .sort((a, b) => a.attributes.finalRank! < b.attributes.finalRank! ? -1 : 1)
    const gridHeight = "400px"
    const PlayerCard = (props: {
        player: Participant
    }) => {
        const {player} = props
        return (
            <>
                <Center bg={"gray.900"} color={"white"} mt={"auto"} fontSize={"xx-large"}>{formatOrdinals(player.attributes.finalRank!)}</Center>
                <Center>{player.attributes.name}</Center>
            </>
        )
    }

    return (
        <>
            {
                !isFinalized ?
                    <Center>Results are not available yet.</Center>
                    :
                    <>
                        <Center><Heading fontSize="5em">TOP {top8.length}</Heading></Center>

                        <Box position='relative' padding='10'>
                            <Divider borderColor="black" variant="thick" />
                            <AbsoluteCenter py={3} bg='black' px='4' borderRadius={7}>
                                <Icon fontSize="2em" icon="octicon:feed-trophy-16" color="white"/>
                            </AbsoluteCenter>
                        </Box>


                        <Grid
                            gridTemplateColumns={"repeat(3, 1fr)"}
                            gap={2}>
                            <GridItem colSpan={1}>
                                <Grid
                                    templateColumns={"repeat(1, 1fr)"}
                                    templateRows={"repeat(2, 1fr)"}
                                    gap={2}
                                    h={gridHeight}>
                                    {
                                        top8.map((player, index) => {
                                            if (!index) return (
                                                <GridItem bgColor={"orange.400"} rowSpan={2}>
                                                    <PlayerCard player={player}/>
                                                </GridItem>
                                            )
                                            else return <></>
                                        })
                                    }
                                </Grid>
                            </GridItem>

                            <GridItem colSpan={2}>
                                <Grid
                                    templateColumns={"repeat(12, 1fr)"}
                                    templateRows={"repeat(2, 1fr)"}
                                    gap={2}
                                    h={gridHeight}>
                                    {
                                        top8.map((player, index, arr) => {
                                            if (!index) return <></>
                                            if (index < 4) {
                                                return (
                                                    <GridItem bgColor={"orange.300"} rowSpan={arr.length === 4 ? 2 : 1} gridColumn={`auto / span 4`}>
                                                        <PlayerCard player={player}/>
                                                    </GridItem>
                                                )
                                            }
                                            return (
                                                <GridItem bgColor={"orange.200"} gridColumn={`auto / span 3`}>
                                                    <PlayerCard player={player}/>
                                                </GridItem>
                                            )
                                        })
                                    }
                                </Grid>
                            </GridItem>
                        </Grid>

                        {
                            theRest.length &&
                                <>
                                  <Box position='relative' padding='10'>
                                    <Divider borderColor="black" />
                                    <AbsoluteCenter py={3} bg='black' border="1px solid" px='4' borderRadius={4}>
                                        <Icon icon="fa:chevron-down" color="white" />
                                    </AbsoluteCenter>
                                  </Box>
                                  <SimpleGrid columns={{base: 1, md: theRest.length % 6}} spacing={2}>
                                      {
                                          theRest.map((player) => {
                                              return (
                                                  <GridItem
                                                      borderWidth={1}
                                                      borderColor={"gray.300"}
                                                      borderRadius={7}>
                                                      <SimpleGrid columns={4} alignItems={"center"}>
                                                          <GridItem p={4} bg={"gray.800"} color={"white"} borderTopLeftRadius={7} borderBottomLeftRadius={7}>
                                                              <Center>{formatOrdinals(player.attributes.finalRank!)}</Center>
                                                          </GridItem>
                                                          <GridItem colSpan={3}>
                                                              <Center>{player.attributes.name}</Center>
                                                          </GridItem>
                                                      </SimpleGrid>
                                                  </GridItem>
                                              )
                                          })
                                      }
                                  </SimpleGrid>
                                </>
                        }
                    </>
            }
        </>
        )
}

export default TournamentResults
