import {
    Box,
    Card,
    CardBody,
    CardFooter,
    Heading,
    LinkBox,
    LinkOverlay,
    List,
    ListIcon,
    ListItem,
    Skeleton,
    Stack
} from "@chakra-ui/react";
import MarkerIcon from "../../icons/MarkerIcon";
import GroupIcon from "../../icons/GroupIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import TournamentStateBadge from "../../badges/TournamentStateBadge";
import GameIcon from "../../icons/GameIcon";
import {TournamentContext, UserContext} from "../../../contexts/main";
import React, {useContext} from "react";
import {formatTime} from "../../../utility";

const TournamentCard = (props: {
    children: any
}) => {
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const { children } = props
    const playerCount = tournament.relationships.participants.links.meta.count
    const baseURL = `${window.location.protocol}//${window.location.host}/tournaments`

    return (
        <Card maxW='sm' minW={'sm'} variant="outline" bg={"gray.600"} borderColor={"gray.700"}>
            <LinkBox>
                <LinkOverlay href={`${baseURL}/${tournament.id}`}>
                    <CardBody>
                        <Skeleton height={200} width={340}/>

                        <Box sx={{transform: "translate(0%, -100%)"}}>
                            <TournamentStateBadge state={tournament.attributes.state}/>
                        </Box>

                        <Stack spacing='3' p={3} color="gray.900">
                            <Heading size='md'>
                                {`${tournament.attributes.name}`.length > 29 ? `${tournament.attributes.name}`.substring(0, 28) + '...' : tournament.attributes.name}
                            </Heading>

                            <List spacing={1} color="gray.300">
                                <ListItem>
                                    <Box display="inline-block" mr={1}>
                                        <ListIcon as={GameIcon}/>
                                    </Box>
                                    {tournament.attributes.gameName}
                                </ListItem>
                                <ListItem>
                                    <Box display="inline-block" mr={1}>
                                        <ListIcon as={CalendarIcon}/>
                                    </Box>
                                    {formatTime(tournament.attributes.timestamps.startsAt)}
                                </ListItem>
                                <ListItem>
                                    <Box display="inline-block" mr={1}>
                                        <ListIcon as={MarkerIcon}/>
                                    </Box>
                                    Online
                                </ListItem>
                                <ListItem>
                                    <Box display="inline-block" mr={1}>
                                        <ListIcon as={GroupIcon}/>
                                    </Box>
                                    {playerCount}
                                </ListItem>
                            </List>
                        </Stack>
                    </CardBody>
                </LinkOverlay>
            </LinkBox>
            { !!user ? <CardFooter>{children}</CardFooter> : <></> }
        </Card>
    )
}

export default TournamentCard
